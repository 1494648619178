package csaware.kpi_statistics

import dk.rheasoft.csaware.api.statistics.StatisticCounter
import dk.rheasoft.csaware.api.statistics.StatisticCounter.CountType
import kafffe.core.KafffeComponent
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier

class KpiPage : KafffeComponent() {
    private fun counterSetup(title: String, countTitle: String, subTitle: String, type: CountType) =
        counterSetup(Model.of(title), countTitle, subTitle, type)

    init {
        cssClassModifier("accordion")
    }

    private fun counterSetup(
        titleModel: Model<String>, countTitle: String, subTitle: String, type: CountType
    ): Pair<Model<String>, StatisticCounter> =
        Pair(
            titleModel,
            StatisticCounter(
                title = countTitle,
                subTitle = subTitle,
                countType = type
            )
        )

    private val section1: StatisticSectionView = addChild(
        StatisticSectionView(
            KpiDimensions.d1.titleModel,
            listOf(
                counterSetup("Policy Count", "policy", "count", CountType.TOTAL),
                counterSetup("Policy Changes Weekly", "policy", "changes", CountType.WEEKLY),
                counterSetup("Policy Changes Monthly", "policy", "changes", CountType.MONTHLY),
                counterSetup("System Graph Assets", "system graph", "assets", CountType.TOTAL),
                counterSetup("System Graph Dependencies", "system graph", "dependencies", CountType.TOTAL),
                counterSetup("System Graph Values", "system graph", "fields with value", CountType.TOTAL),
                counterSetup("System Graph Changes Weekly", "system graph", "changes", CountType.WEEKLY),
                counterSetup("System Graph Changes Monthly", "system graph", "changes", CountType.MONTHLY),
                counterSetup("Questionnaire", "questionnaire", "d1", CountType.TOTAL),
            )
        ).apply{ toggleCollapsed() }
    )

    private val section2: StatisticSectionView = addChild(
        StatisticSectionView(
            KpiDimensions.d2.titleModel,
            listOf(
                counterSetup("Incident Total", "incident", "count", CountType.TOTAL),
                counterSetup("Incident Sharing Eco", "incident", "sharing count eco", CountType.TOTAL),
                counterSetup("Incident Sharing Public", "incident", "sharing count public", CountType.TOTAL),
                counterSetup("Incident Sharing changes Weekly", "incident", "access_level_changes", CountType.WEEKLY),
                counterSetup("Incident Sharing changes Monthly", "incident", "access_level_changes", CountType.MONTHLY),
                // TODO policies + system graph sharing
                counterSetup("Questionnaire", "questionnaire", "d2", CountType.TOTAL),
            )
        ).apply{ toggleCollapsed() }
    )

    private val section3: StatisticSectionView = addChild(
        StatisticSectionView(
            KpiDimensions.d3.titleModel,
            listOf(
                counterSetup("Logins Weekly", "frontend", "authenticate", CountType.WEEKLY),
                counterSetup("Logins Monthly", "frontend", "authenticate", CountType.MONTHLY),
                counterSetup("Distinct User Weekly", "frontend", "distinct_users", CountType.WEEKLY),
                counterSetup("Distinct User Monthly", "frontend", "distinct_users", CountType.MONTHLY),
                counterSetup("Anomaly Count", "anomaly", "count", CountType.TOTAL),
                counterSetup("Anomaly New Weekly", "anomaly", "new", CountType.WEEKLY),
                counterSetup("Anomaly New Monthly", "anomaly", "new", CountType.MONTHLY),
                counterSetup("Anomaly Ignored Weekly", "anomaly", "ignore", CountType.WEEKLY),
                counterSetup("Anomaly Ignored Monthly", "anomaly", "ignore", CountType.MONTHLY),
                counterSetup("Anomaly Incident Weekly", "anomaly", "incident", CountType.WEEKLY),
                counterSetup("Anomaly Incident Monthly", "anomaly", "incident", CountType.MONTHLY),
                counterSetup("Questionnaire", "questionnaire", "d3", CountType.TOTAL),
                )
        ).apply{ toggleCollapsed() }
    )

    private val section4: StatisticSectionView = addChild(
        StatisticSectionView(
            KpiDimensions.d4.titleModel,
            listOf(
                counterSetup("System Graph Assets", "system graph", "assets", CountType.TOTAL),
                counterSetup("System Graph Dependencies", "system graph", "dependencies", CountType.TOTAL),
                counterSetup("System Graph Values", "system graph", "fields with value", CountType.TOTAL),
                counterSetup("System Graph Changes Weekly", "system graph", "changes", CountType.WEEKLY),
                counterSetup("System Graph Changes Monthly", "system graph", "changes", CountType.MONTHLY),
                counterSetup("Incident Sharing Public", "incident", "sharing count public", CountType.TOTAL),
                counterSetup("Questionnaire", "questionnaire", "d4", CountType.TOTAL),
            )
        ).apply{ toggleCollapsed() }
    )

    private val section5: StatisticSectionView = addChild(
        StatisticSectionView(
            KpiDimensions.d5.titleModel,
            listOf(
                counterSetup("System graph security appliances", "system graph", "security appliances", CountType.TOTAL),
                counterSetup("Policy standards", "policy", "standards", CountType.TOTAL),
                counterSetup("Questionnaire", "questionnaire", "d5", CountType.TOTAL),
            )
        ).apply{ toggleCollapsed() }
    )

    private val questionnaire: StatisticSectionView = addChild(
        QuestionnaireSection(
            Model.of("Questionnaire"),
        )
    )

}
