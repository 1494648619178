package csaware.anomalies

import csaware.main.CsawareServices
import csaware.messages.CsawareMessagesObject
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

open class ToBeIgnoredCollector(val dragHolder: AnomalyDragHolder, targetsModel: Model<List<Target>>) : KafffeComponent() {
    val anomaliesModel: Model<List<AnomalyWrapper>> = Model.of(emptyList())

    private val anomaliesTable = addChild(AnomaliesTable(anomaliesModel, dragHolder, targetsModel))

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("card")
            withElement {
                ondragenter = { true }
                ondragover = { dragEvent -> dragEvent.preventDefault() }
                ondrop = { dragEvent -> dragHolder.moveTo(anomaliesModel) }
            }
            h3 {
                addClass("card-header")
                i { addClass("fa-solid fa-eye-slash me-2") }
                text("TODO: To be Ignored")
            }
            div {
                addClass("card-body")
                add(anomaliesTable.html)
            }
            div {
                addClass("card-footer")
                button {
                    addClass("btn btn-primary")
                    withElement {
                        onclick = {
                            save()
                        }
                    }
                    text(CsawareMessagesObject.get().save)
                }
            }
        }

    fun hasUnsavedChanges(): Boolean =
        anomaliesModel.data.isNotEmpty()

    fun save() {
        CsawareServices.anomalyBackend.storeIgnored(anomaliesModel.data) {
            CsawareServices.alerts.infoAdd(CsawareMessagesObject.get().anomalies_ignores_saved)
            anomaliesModel.data = emptyList()
        }
    }
}