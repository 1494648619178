package csaware.threats

import csaware.main.UserConfiguration
import csaware.messages.CsawareMessagesObject
import csaware.messages.threatState
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.api.incident.ThreatState
import kafffe.bootstrap.external.Placement
import kafffe.bootstrap.external.placementEnum
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class ThreatStateSymbol(
    model: Model<ThreatObservation>,
    val includeSeverityText: Boolean
) : KafffeComponentWithModel<ThreatObservation>(model) {
    init {
        val mod = BootstrapTooltipModifier(Model.of(CsawareMessagesObject.get().threatState(model.data.state)))
        mod.options.placementEnum = Placement.right
        modifiers.add(mod)
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return span {
            withStyle {
                color = UserConfiguration.default.severityTextColor(model.data.severity  ?: 0)
                whiteSpace = "nowrap"
            }
            when (model.data.state) {
                ThreatState.Active -> {
                    i { addClass("fa-2x fas fa-exclamation-triangle ") }
                }
                ThreatState.HealingAwaitDecision -> {
                    i { addClass("fa-2x fas fa-shield-alt") }
                    i { addClass("fa-1x fas fa-question faa-shake animated") }
                }
                ThreatState.HealingAccepted -> {
                    i { addClass("fa-2x fas fa-shield-alt") }
                    i { addClass("fa-1x fas fa-thumbs-up") }
                }
                ThreatState.HealingDeclined -> {
                    i { addClass("fa-2x fas fa-shield-alt") }
                    i { addClass("fa-1x fas fa-thumbs-down") }
                }
                ThreatState.HealingFailed -> {
                    i { addClass("fa-2x fas fa-shield-alt") }
                    i { addClass("fa-1x fas fa-bolt") }
                }
                ThreatState.HealingInProgress -> {
                    i { addClass("fa-2x fas fa-shield-alt") }
                    i { addClass("fa-1x fas fa-spinner fa-pulse") }
                }
                ThreatState.Resolved -> i { addClass("fa-2x fas fa-check") }
                ThreatState.Ignored -> i { addClass("fa-2x fas fa-eye-slash") }
                ThreatState.Healed -> {
                    i { addClass("fa-2x fas fa-shield-alt") }
                    i { addClass("fa-1x fas fa-check") }
                }
                ThreatState.Unknown -> i { addClass("fas fa-question") }
            }
            if (includeSeverityText) {
                text(" ")
                text(CsawareMessagesObject.get().threatLevelName(model.data.severity))
            }
        }
    }
}