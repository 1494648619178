package csaware.messages

import kafffe.messages.Messages_el

open class CsawareMessages_el : Messages_el(), CsawareMessages {
    override val translation_automatic: String = "Αυτόματη μετάφραση"
    override val settings: String = "Ρυθμίσεις"

    override val environment_name = "Ονομα"

    override val nav_overview = "Επισκόπηση"
    override val nav_threats = "Απειλές"
    override val nav_threatsClosed = "Απειλές Κλειστές"
    override val nav_config = "Παραμετροποίηση"
    override val threat_group = "Ομάδα"
    override val threat_group_all = "Όλα"
    override val threat_nobody = "Κανένας"
    override val threat_state = "Κατάσταση"
    override val threat_state_initator = "Ποιός"
    override val threat_state_history = "Ιστορικό"
    override val threat_state_comment = "Comment"
    override val threat_course_of_action = "Πορεία Ενεργειών"
    override val threat_where = "Πού"
    override val threat_assignee = "Εντολοδόχος"

    override val threat_observed_data_objects = "Observed Data"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Type"
    override val threat_observed_data_data = "Data"

    override val threat_subtype = "Υπό-Τύπος"
    override val threat_firstObserved = "Πρώτη εμφάνιση"
    override val threat_endActive = "Έκλεισε στις"
    override val threat_newState = "Αλλαγή Κατάστασης"
    override val threat_selfHealingConfirm = "Επιβεβαίωση Αυτο-Θεραπείας"

    override val threat_topTitle = "Κύριες Απειλές"

    override val severity = "Σοβαρότητα"
    override val count = "Πλήθος"

    override val threat_level_5_name = "Κρίσιμη"
    override val threat_level_5_text = "Αναμένεται επίθεση"
    override val threat_level_4_name = "Σοβαρή"
    override val threat_level_4_text = "Μια επίθεση είναι πολύ πιθανή"
    override val threat_level_3_name = "Πιθανή"
    override val threat_level_3_text = "Μια επίθεση είναι πολύ πιθανό να συμβεί"
    override val threat_level_2_name = "Μέτρια"
    override val threat_level_2_text = "Μια επίθεση είναι δυνατή, αλλά δεν είναι πιθανή."
    override val threat_level_1_name = "Χαμηλή"
    override val threat_level_1_text = "Μια επίθεση είναι απίθανη."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Ενεργή"
    override val ThreatState_Resolved = "Επιλύθηκε"
    override val ThreatState_Ignored = "Αγνοήθηκε"
    override val ThreatState_HealingAwaitDecision = "Η Αυτο-θεραπεία χρειάζεται απόφαση"
    override val ThreatState_HealingAccepted = "Αποδοχή Αυτο-θεραπείας"
    override val ThreatState_HealingDeclined = "Απόρριψη Αυτο-θεραπείας"
    override val ThreatState_Healed = "Ολοκλήρωση Αυτο-θεραπείας"
    override val ThreatState_HealingFailed = "Αποτυχία Αυτο-θεραπείας"
    override val ThreatState_HealingInProgress = "Αυτο-θεραπεία σε εξέλιξη"
    override val ThreatState_Unknown = "Άγνωστο"

    override val user_management = "Διαχείριση Χρήστη"
    override val user_edit = "Επεξεργασία Χρήστη"
    override val user_new = "Νέος Χρήστης"
    override val user_subject = "Αριθμός"
    override val user_email = "Email"
    override val user_firstName = "Όνομα"
    override val user_lastName = "Επώνυμο"
    override val user_fullname  = "Όνομα"
    override val user_roles = "Ρόλοι"
    override val user_action_import = "Εισαγωγή"
    override val user_action_export = "Εξαγωγή"

    override val user_preferences_edit ="Προτιμήσεις διεπαφής χρήστη"
    override val user_preferences_dataPresentationLanguage = "Γλώσσα παρουσίασης δεδομένων"
    override val user_preferences_uiLanguage ="Γλώσσα διεπαφής χρήστη"

    override val system_preferences_edit ="Επιλογές συστήματος"
    override val system_preferences_accountDataPresentationLanguage = "Προεπιλεγμένη γλώσσα παρουσίασης δεδομένων"
    override val system_preferences_accountUiLanguage ="Προεπιλεγμένη γλώσσα διεπαφής χρήστη"

    override val informationshare_management = "Διαμοιρασμός Πληροφορίας"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Επεξεργασία Διαμοιρασμού Πληροφορίας"
    override val informationshare_id = "Id"
    override val informationshare_description = "Περιγραφή"
    override val informationshare_state = "Κατάσταση"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Τελευταία Τροποποίηση"
    override val informationshare_initiator = "Ποιός"
    override val informationshare_history = "Ιστορικό"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Επιβεβαίωση Διαμοιρασμού"
    override val ShareState_Accept = "Αποδοχή"
    override val ShareState_Decline = "Άρνηση"
    override val ShareState_Unknown = "Άγνωστο"

    override val system_dependencies = "Σύστημα"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Όνομα"
    override val system_depend_description= "Περιγραφή"
    override val system_depend_connectedFrom= "Σύνδεση Από"
    override val system_depend_connectedTo= "Σύνδεση Προς"
    override val system_depend_infoflow= "Ροή Πληροφορίας"
    override val system_depend_category= "Κατηγορία"
    override val system_depend_fields= "Πεδία"
    override val system_depend_fields_valueset = "Ομάδα Τιμών Πεδίων"
    override val system_depend_fields_valueset_values = "Τιμές"
    override val system_depend_node_types = "Τύπος Κόμβου"
    override val system_depend_layout = "Μορφή"
    override val system_depend_layoutDirection = "Κατεύθυνση"
    override val system_depend_layout_horizontal = "Οριζόντια"
    override val system_depend_layout_vertical = "Κάθετη"
    override val system_depend_layout_roots = "Προέλευση"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Απόσταση"

    override val system_depend_action_search = "Αναζήτηση Πόρου Συστήματος"
    override val system_depend_action_new = "Δημιουργία Νέου"
    override val system_depend_action_edit = "Επεξεργασία"
    override val system_depend_action_delete = "Διαγραφή"
    override val system_depend_action_delete_confirm_title = "Διαγραφή Πόρου Συστήματος"
    override val system_depend_action_config = "Παραμετροποίηση"
    override val system_depend_action_import = "Εισαγωγή"
    override val system_depend_action_export = "Εξαγωγή"
    override val system_depend_action_config_import  = "Παραμετροποίηση Εισαγωγή"
    override val system_depend_action_config_export  = "Παραμετροποίηση Εξαγωγή"
    override val system_depend_action_print = "Εκτύπωση"

    override val system_depend_action_backup_or_restore = "Αντίγραφο ασφαλείας ή Επαναφορά"
    override val system_depend_backup_title = "Αντίγραφο ασφαλείας Πόρων Συστήματος"
    override val system_depend_backup_create_new = "Νέο αντίγραφο ασφαλείας"
    override val system_depend_backup_created = "Δημιουργήθηκε"
    override val system_depend_backup_comment = "Σχόλιο"
    override val system_depend_backup_nodes_not_in_current = "Όχι στο τρέχον"
    override val system_depend_backup_nodes_in_current_not_in_this = "Όχι σε αυτό"
    override val system_depend_backup_nodes_changed = "Αλλαγμένο"
    override val system_depend_restore_state = "Αλλαγή"
    override val system_depend_restore_operation = "Λειτουργία"
    override val system_depend_restore_operation_remove = "Αφαίρεση"
    override val system_depend_restore_operation_restore = "Επαναφορά"
    override val system_depend_restore_revert_all_nodes = "Επαναφορά όλων των Κόμβων Συστήματος"
    override val system_depend_restore_revert_configuration = "Επαναφορά Διαμόρφωσης Εξαρτήσεων Συστήματος"

    override val system_depend_label_all = "Όλα"

    override val system_depend_field_id = "Id"
    override val system_depend_field_label = "ετικέτα"
    override val system_depend_field_type = "τύπος"
    override val system_depend_field_cardinality = "καρτελικότητα"
    override val system_depend_field_add = "Συμπερίληψη πεδίου"
    override val system_depend_fields_config = "Διαμόρφωση πεδίου.."
    override val system_depend_field_validation_error_txt = "'%value%' δεν ισχύει για '%type%'"

    override val sign_out = "Αποσύνδεση"
    override val sign_out_disclaimer = """
        |Η συνεδρία του CS-AWARE Visualisation έχει εκαθαριστεί,  
        |θα πρέπει να αποσυνδεθείτε από τον εξωτερικό Πάροχο Εξουσιοδότησης,  
        |εάν δεν επιθυμείτε να συνδεθείτε ξανά αυτόματα. 
        |""".trimMargin()
    override val sign_out_link="Μεταβείτε στον Πάροχο Εξουσιοδότησης προκειμένου να αποσυνδεθείτε."

    override val mail_dialog_title = "Αποστολή Email"
    override val mail_to = "Παραλήπτης"
    override val mail_from = "Αποστολέας"
    override val mail_subject = "Θέμα"
    override val mail_body = "Σώμα Κειμένου"
    override val mail_send = "Αποστολή"

    override val socialmedia_account_included = "Περιλαμβάνεται"
    override val socialmedia_reader : String = "Socialmedia"
    override val socialmedia_reader_filter_readstate_both = "Τόσο αναγνωσμένα όσο και μη αναγνωσμένα"
    override val socialmedia_reader_filter_readstate_not_read = "Μόνο μη αναγνωσμένο"
    override val socialmedia_reader_filter_readstate_read = "Μόνο αναγνωσμένο"

    override val socialmedia_reader_read_on_source = "διαβάστε την πηγή"
    override val socialmedia_createdAt : String = "Δημιουργήθηκε στο"
    override val socialmedia_keywords : String = "Λέξεις-κλειδιά"
    override val socialmedia_text : String = "Κείμενο"
    override val socialmedia_account : String = "Λογαριασμός"
    override val socialmedia_id:String = "Id"

    override val socialmedia_system_where = "Σχετίζεται με"

    override val socialmedia_management_title = "Διαχείριση κοινωνικών μέσων"
    override val socialmedia_account_edit = "Επεξεργασία"
    override val socialmedia_account_new = "Νέος"
    override val socialmedia_account_mediaType = "Τύπος"
    override val socialmedia_account_collectorState = "Συλλέκτης"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Όνομα"
    override val socialmedia_account_displayName = "Όνομα εμφάνισης"
    override val socialmedia_account_description= "Περιγραφή"
    override val socialmedia_account_lastCollected = "Τελευταία συλλογή"

    override val socialmedia_account_twitter_new = "Συλλογή νέου λογαριασμού X"
    override val socialmedia_account_include = "Συμπεριλάβετε τη συλλογή λογαριασμού"
    override val socialmedia_account_exclude = "Εξαίρεση συλλογής λογαριασμού"

    override val socialmedia_account_action_export = "Εξαγωγή"
    override val socialmedia_account_action_import = "Εισαγωγή"
    override val socialmedia_account_action_include_page = "Συμπεριλάβετε τη συλλογή για λογαριασμούς στη σελίδα"
    override val socialmedia_account_action_exclude_page = "Εξαίρεση συλλογής για λογαριασμούς στη σελίδα"
    override val socialmedia_create_threatobservation = "Δημιουργία έκθεσης απειλών"

    override val policy = "Πολιτική"
    override val policy_edit = "Edit Πολιτική"
    override val policy_title: String = "Title"
    override val policy_purpose = "Purpose"
    override val policy_elements = "Elements"
    override val policy_need = "Need"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "References"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences = "System resource references"
    override val policy_new  = "Create policy"
    override val policy_createdAt = "Created at"
    override val policy_createdBy = "Created by"
    override val policy_updatedAt = "Updated at"
    override val policy_updatedBy = "Updateted by"

    override val policy_state  = "Κατάσταση"
    override val policy_state_draft = "Προσχέδιο"
    override val policy_state_approved = "Εγκρίθηκε"
    override val policy_state_draft_with_approved= "Προσχέδιο με εγκεκριμένη έκδοση"
    override val policy_state_obsolete = "Απαρχαιωμένος"
    override val policy_state_unknown = "Άγνωστο"

    override val policy_type_organisation = "Οργάνωση"
    override val policy_type_template = "Περίγραμμα"

    override val policy_comment_comment = "Σχόλιο"
    override val policy_comment_add = "Προσθήκη σχολίου"
    override val policy_comment_edit = "Επεξεργασία σχολίου"
    override val policy_comment_respond = "Απάντηση"
    override val policy_comment_delete = "Διαγραφή σχολίου"

    override val policy_approve_comment_add = "προσθήκη σχολίου έγκρισης"

    override val policy_history = "Ιστορία"

    override val policy_action_edit = "Επεξεργασία"
    override val policy_action_clone = "Κλωνοποίηση"
    override val policy_action_approve = "Έγκριση"
    override val policy_action_export = "Εξαγωγή"

    override val policy_action_ai_import = "Πολιτική εισαγωγής AI.."
    override val policy_import_extract_text = "Εξαγωγή κειμένου από έγγραφο (αρχείο)"

    override val policy_config  = "Διαμόρφωση ετικετών και τύπων σχέσεων"
    override val policy_relation_type = "Τύπος σχέσης"
    override val policy_relation_id = "Ταυτότητα"
    override val policy_relation_name = "Όνομα"
    override val policy_relation_reversed = "Αντίστροφη σχέση"
    override val policy_template_action_edit= "Νέο από το πρότυπο"

    override val markdown_bold = "Έντονο"
    override val markdown_italic = "Πλάγια γραφή"
    override val markdown_strikethrough = "Διαγράψιμο"
    override val markdown_heading = "Επικεφαλίδα"
    override val markdown_heading_smaller = "Επικεφαλίδα μικρότερη"
    override val markdown_heading_bigger = "Μεγαλύτερη επικεφαλίδα"
    override val markdown_heading_1x = "Επικεφαλίδα 1"
    override val markdown_heading_2x = "Επικεφαλίδα 2"
    override val markdown_heading_3x = "Επικεφαλίδα 3"
    override val markdown_code = "Κωδικός"
    override val markdown_quote = "Παράθεση"
    override val markdown_unordered_list = "Μη ταξινομημένη λίστα"
    override val markdown_ordered_list = "Ταξινομημένη λίστα"
    override val markdown_clean_block = "Καθαρό _block"
    override val markdown_link = "Εισαγωγή συνδέσμου"
    override val markdown_image = "Εισαγωγή εικόνας"
    override val markdown_mde_table = "Εισαγωγή πίνακα"
    override val markdown_horizontal_rule = "Οριζόντιος κανόνας"
    override val markdown_preview = "Εναλλαγή προεπισκόπησης"
    override val markdown_side_by_side = "Προβολή πλάι-πλάι"
    override val markdown_fullscreen = "Πλήρης οθόνη"
    override val markdown_guide = "Οδηγός"
    override val markdown_undo = "Αναίρεση"
    override val markdown_redo = "Επαναφορά"

}

