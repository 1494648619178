package csaware.kpi_statistics

import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.statistics.StatisticEntry
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import externals.echarts.*
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kafffe.utils.DomObserverUtils
import org.w3c.dom.HTMLDivElement

class QuestionnaireColumnChart(
    val titleModel: Model<String>,
) : KafffeComponent() {

    private val entries: MutableMap<String, Float>  = mutableMapOf()
    private val averageEntries: MutableMap<String, Float> = mutableMapOf()
    var height: String by rerenderOnChange("40rem")
    var width: String by rerenderOnChange("60rem")

    init {
        load()
    }

    private fun load() {
        val parameters = mapOf(
            "title" to "questionnaire",
            "countType" to "TOTAL",
            "offset" to "0",
            "limit" to "1"
        )
        for (dim in KpiDimensions.entries) {
            val parms = parameters + ("subTitle" to dim.questionnaireSubTitle)
            CsawareServices.backend.getTxt("/statistics/counter/newest", parms) {
                val list: List<StatisticEntry> = JsonUtilSerialization.json.decodeFromString(it)
                list.firstOrNull()?.let {
                    entries[dim.questionnaireSubTitle] = it.value
                    if (entries.size == KpiDimensions.entries.size && entries.size == averageEntries.size) {
                        rerender()
                    }
                }
            }
            CsawareServices.backend.getTxt("/statistics/counter/average/newest", parms) {
                val list: List<StatisticEntry> = JsonUtilSerialization.json.decodeFromString(it)
                list.firstOrNull()?.let {
                    averageEntries[dim.questionnaireSubTitle] = it.value
                    if (entries.size == KpiDimensions.entries.size && entries.size == averageEntries.size) {
                        rerender()
                    }
                }
            }
        }
    }

    fun chart(chartElement: HTMLDivElement) {
        var myChart: ZRender = init(chartElement)
        var option: EChartsOption = js(
            """{
                   xAxis : {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisLabel: {
                            rotate : 10,
                            align: 'center',
                            verticalAlign: 'top',
                            padding: 30
                        }
                   },
                   yAxis: { type: 'value' },
                   legend: { data: ['This', 'Average'] },
                   series: [
                        {
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: 'bar'
                        }
                   ]
            }
            """
        ).unsafeCast<EChartsOption>()
        option.tooltip = js("{}")
        with (option.tooltip as TooltipOption) {
            trigger = "axis"
            axisPointer = js("{}")
            axisPointer!!.type = "shadow"
        }
        option.xAxis.data = KpiDimensions.entries.map{ it.title }.toTypedArray()
        val barSeriesOptions = (option.series as Array<BarSeriesOption>)
        barSeriesOptions[0].name = "This"
        barSeriesOptions[0].data = KpiDimensions.entries.map {  entries[it.questionnaireSubTitle] ?: 0.0 }.toTypedArray()
        option.legend.data = listOf("This", "Average").toTypedArray()
        if (averageEntries != entries) {
            val average = js("{}") as BarSeriesOption
            average.name = "Average"
            average.type = "bar"
            average.data =  KpiDimensions.entries.map {  averageEntries[it.questionnaireSubTitle] ?: 0.0 }.toTypedArray()
            barSeriesOptions[1] = average
        }
        console.log(JSON.stringify(option))
        myChart.setOption(option)
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("csaware_counter_column_chart")
            withElement {
                style.height = height
                style.width = width
            }
            DomObserverUtils.whenVisibleInViewport(this.element) {
                chart(it)
            }
        }
}