package csaware.socialmedia

import csaware.comm.ThreatsBackend
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import csaware.threats.ThreatCreateDlg
import csaware.utilities.componentTitle
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import dk.rheasoft.csaware.api.socialmedia.SocialMediaType
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.core.*
import kafffe.core.modifiers.AttachAwareModifier.Companion.attachAwareModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kotlinx.datetime.Clock
import org.w3c.dom.HTMLDivElement
import kotlin.time.Duration.Companion.days

class SocialMediaDetails(
    model: Model<SocialMediaEntry?>,
    val whereSightedRefsModel: Model<Set<String>>,
    private val graphService: SystemDependencyService,
    val includeTitle: Boolean = true
) :
    KafffeComponentWithModel<SocialMediaEntry?>(model) {
    private val titleLabel = addChild(Label(i18nText(CsawareMessages::socialmedia_reader)))

    private val createTextModel: Model<String> = Model.ofGet {
        if (threatObservation != null) {
            CsawareMessagesObject.get().threat_newState
        } else {
            CsawareMessagesObject.get().socialmedia_create_threatobservation
        }
    }
    private val showCreateThreatButton = addChild(BootstrapButton(createTextModel, ::showCreateThreat).apply {
        iconClasses = "fas fa-exclamation-triangle"
        iconBefore = true
        color = BasicColor.secondary
        cssClassModifier("ms-4 mb-4")
    })

    init {
        attachAwareModifier(onAttach = { addTwitterWidgetsJs() })
    }

    private var threatObservation: ThreatObservation? = null
    override fun modelChanged() {
        threatObservation = null
        rerender()
        fun notFoundHandler(code: Int, message: String) {
            if (code == 404) {
                threatObservation = null
                showCreateThreatButton.rerender()
            } else {
                // normal exception handling
                CsawareServices.exceptionHandler(code, message)
            }
        }

        val soMe = model.data!!.observation
        val id = "some-${soMe.id}"
        ThreatsBackend(::notFoundHandler).threatWithDetails(id) { observationLoaded ->
            threatObservation = observationLoaded
            showCreateThreatButton.rerender()
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            div {
                if (includeTitle) {
                    componentTitle {
                        add(titleLabel.html)
                    }
                }

                model.data?.let { data ->
                    when (data.observation.sourceType) {
                        SocialMediaType.Twitter -> {
                            div {
                                addClass("m-4")
                                applyTwitterScript(element)
                                withStyle {
                                    // twitter defaults to maxWidth 550px and auto margin, we do not want it to be centered
                                    maxWidth = "560px"
                                }
                            }
                        }

                        SocialMediaType.Reddit -> {
                            redditPreview(data)
                        }

                        else -> {
                            otherPreview(data)
                        }
                    }

                    add(showCreateThreatButton.html)
                }
            }
        }
    }

    private fun showCreateThreat(@Suppress("UNUSED_PARAMETER") btn: BootstrapButton) {
        val soMe: SocialMediaEntry = model.data ?: return
        val description = soMe.getText()
        val title = soMe.getTitle().ifEmpty { if (description.length <= 50) description else description.take(47) + "..." }
        val link = when (soMe.observation.sourceType) {
            SocialMediaType.Twitter -> "https://twitter.com/tweet/status/${soMe.observation.id}"
            SocialMediaType.Reddit -> {
                val id =  soMe.observation.id.replace("t3_", "")
                "https://redd.it/$id"
            }
            else -> soMe.observation.id
        }
        val report = ThreatObservation(
            id = "some-${soMe.observation.id}",
            threatGroup = "Social Media",
            name = title,
            description = description + (if (link.isEmpty()) "" else  "\n\n[$link]($link)\n"),
            assignee = UserInformation.current.email,
            firstObserved = Clock.System.now(),
            endActive = Clock.System.now() + 365.days,
            lastObserved = Clock.System.now(),
            severity = 2,
            whereSightedRefs = whereSightedRefsModel.data.toMutableSet()
        )
        ThreatCreateDlg.show(report, graphService)
    }

    private fun applyTwitterScript(element: HTMLDivElement) {
        model.data?.let { data ->
            val observation = data.observation
            if (observation.id.isNotEmpty()) {
                when (observation.sourceType) {
                    SocialMediaType.Twitter -> {
                        createTweet(observation.id, element)
                    }

                    else -> {}
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.redditPreview(data: SocialMediaEntry) =
        div {
            addClass("border rounded m-4 p-4")
            if (data.observation.title.isNotEmpty()) {
                h3 {
                    i {
                        addClass("sy sy_medium sy_reddit me-2")
                    }
                    text(data.observation.title)
                }
            }
            div {
                if (data.observation.text.isNotEmpty()) {
                    add(MarkdownRender(data.observation.text).html)
                }
            }
            a {
                addClass("btn btn-info mt-4 mb-4")
                i {
                    addClass("sy sy_small sy_reddit me-2")
                }
                withElement {
                    val id =  data.observation.id.replace("t3_", "")
                    href = "https://redd.it/$id"
                    target = "_blank"
                }
                text(CsawareMessagesObject.get().socialmedia_reader_read_on_source)
            }
        }

    private fun KafffeHtml<HTMLDivElement>.otherPreview(data: SocialMediaEntry) =
        div {
            addClass("border rounded m-4 p-4")
            if (data.observation.title.isNotEmpty()) {
                h3 {
                    i {
                        addClass("sy sy_medium sy_rss me-2")
                    }
                    text(data.observation.title)
                }
            }
            div {
                if (data.observation.text.isNotEmpty()) {
                    add(MarkdownRender(data.observation.text).html)
                }
            }
            a {
                addClass("btn btn-info mt-4 mb-4")
                i {
                    addClass("sy sy_small sy_rss me-2")
                }
                withElement {
                    href = data.observation.id
                    target = "_blank"
                }
                text(CsawareMessagesObject.get().socialmedia_reader_read_on_source)
            }
        }
}