package csaware.socialmedia.administration

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.utilities.ActionBar
import csaware.utilities.EllipsisLabel
import csaware.utilities.SearchInput
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaType
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kotlin.math.ceil

class SocialMediaAccountManagement : KafffeComponent() {
    private val titleLabel = addChild(Label(i18nText(CsawareMessages::socialmedia_management_title)))

    private fun includeExcludeSocialMediaAccount(account:SocialMediaAccount) {
        if (account.excluded) {
            CsawareServices.socialMediaBackend.includeSocialMediaAccount(account) {
                loadData()
            }
        } else {
            CsawareServices.socialMediaBackend.excludeSocialMediaAccount(account) {
                loadData()
            }
        }
    }

    val table = addChild(BootstrapTable.create<SocialMediaAccount>(listOf()) {

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        col(i18nText(CsawareMessages::socialmedia_account_mediaType), { account: SocialMediaAccount ->
            ofKafffeHtml {
                i {
                    addClass(
                        when (account.mediaType) {
                            SocialMediaType.Twitter ->"sy sy_small sy_x-twitter"
                            SocialMediaType.Reddit -> "sy sy_small sy_reddit"
                            else -> "sy sy_small sy_rss"
                        }
                    )
                }
            }
        })
        col(i18nText(CsawareMessages::socialmedia_account_collectorState), { Label(it.collectorState.name) })
        col(i18nText(CsawareMessages::socialmedia_account_id), { Label(it.id) })
        col(i18nText(CsawareMessages::socialmedia_account_name), { Label(it.name) })
        col(i18nText(CsawareMessages::socialmedia_account_displayName), { Label(it.displayName) })
        col(i18nText(CsawareMessages::socialmedia_account_description), { EllipsisLabel("50vw", Model.ofGet { it.description }, lines = 3) })
        col(i18nText(CsawareMessages::socialmedia_account_included), { account: SocialMediaAccount ->
            ofKafffeHtml {
                div {
                    addClass("form-check")
                    input {
                        addClass("form-check-input")
                        withElement {
                            type = "checkbox"
                            checked = !(account.excluded)
                            if (UserInformation.hasAccess(MainFeature.SocialMediaAccount, Permission.Write)) {
                                onclick = { includeExcludeSocialMediaAccount(account) }
                            } else {
                                readOnly = true
                            }
                        }
                    }
                }
            }
        }).apply { rowClick = false }
    })


    var pageSize: Int = 10

    val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })

    private var filterInput: String = ""

    private val searchInput = addChild(SearchInput {
        filterInput = it
        pager.first()
    })

    init {
        pager.changeListeners.add { loadData() }
        loadData()
    }

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)

        CsawareServices.socialMediaBackend.getSocialMediaAccounts(offset, pageSize, filterInput, this::receiveData)
    }

    private fun receiveData(response: QueryResult<SocialMediaAccount>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }
    private val actionBar = addChild(ActionBar().apply {
        dropdownItem(i18nText(CsawareMessages::nav_config), "fas fa-cog") {
            item(i18nText(CsawareMessages::socialmedia_account_action_include_page),"fas fa-file-export") {
                accessRequirement = (MainFeature.SocialMediaAccount to Permission.Write)
                action = ::includeAccountsOnPage
            }
            item(i18nText(CsawareMessages::socialmedia_account_action_exclude_page),"fas fa-file-import") {
                accessRequirement = (MainFeature.SocialMediaAccount to Permission.Write)
                action = ::exludeAccountsOnPage
            }
        }
    })


    private fun includeAccountsOnPage() {
        var accounts = table.data
        accounts = accounts.filter { it.excluded == true }
        if (accounts.size > 0){
            CsawareServices.socialMediaBackend.includeSocialMediaAccounts(accounts){
                loadData()
            }
        }
    }
    private fun exludeAccountsOnPage() {
        var accounts = table.data
        accounts = accounts.filter { it.excluded == false }
        if (accounts.size > 0){
            CsawareServices.socialMediaBackend.excludeSocialMediaAccounts(accounts){
                loadData()
            }
        }
    }
    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            componentTitle {
                add(titleLabel.html)
                add(searchInput.html)
                add(actionBar.html)
            }
            add(table.html)
            add(paginator.html)
        }
    }

}