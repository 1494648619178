package csaware.systemdepend.config

import csaware.messages.CsawareMessagesObject
import csaware.messages.system_depend_field_cardinality_enum
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.systemdependencies.Cardinality
import dk.rheasoft.csaware.api.systemdependencies.FieldType
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyConfig
import dk.rheasoft.csaware.api.systemdependencies.SystemGraphField
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class FieldsEditor(
    model: Model<List<SystemGraphField>>,
    private val modelConfig: Model<SystemDependencyConfig>,
    private val graphService: SystemDependencyService
) :
    KafffeComponentWithModel<List<SystemGraphField>>(model), FormValueProvider {
    private val currentFields: MutableList<SystemGraphField> = model.data.toMutableList()

    private fun indexOfField(field: SystemGraphField) = currentFields.indexOfFirst { it.id == field.id }

    private fun current(field: SystemGraphField): SystemGraphField {
        val ix = indexOfField(field)
        return if (ix == -1) {
            currentFields.add(field)
            field
        } else {
            currentFields[ix]
        }
    }

    private fun updateField(field: SystemGraphField) {
        val ix = indexOfField(field)
        if (ix == -1) {
            currentFields.add(field)
        } else {
            currentFields[ix] = field
        }
    }

    private fun removeField(field: SystemGraphField) {
        val ix = indexOfField(field)
        if (ix != -1) {
            currentFields.removeAt(ix)
        }
    }

    private fun moveField(field: SystemGraphField, offset: Int) {
        val ix = indexOfField(field)
        val newIx = ix + offset
        if (ix != -1 && newIx in (0 until currentFields.size)) {
            currentFields.removeAt(ix)
            currentFields.add(newIx, field)
        }
    }

    override fun updateValueModel() {
        model.data = currentFields.toList()
    }

    private val noValueSet = "Not Restricted"

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        val idWidth = "18rem"
        val labelWidth = "14rem"
        val typeWidth = "10rem"
        val cardinalityWidth = "8rem"
        val valuesetWidth = "14rem"
        val stylepatternWidth = "10rem"
        val selectedbydefaultWidth = "8rem"
        val singleKeys = graphService.model.data.flatMap { it.data.keys }.toList()
        val multiKeys = graphService.model.data.flatMap { it.dataLists.keys }.toList()
        val count: Map<String, Int> = (singleKeys + multiKeys).groupingBy { it }.eachCount()
        return div {
            addClass("form-group")
            div {
                addClass("list-group")
                div {
                    addClass("form-inline mt-2")
                    label {
                        withStyle {
                            width = idWidth
                            maxWidth = idWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_field_id)
                    }
                    label {
                        withStyle {
                            width = labelWidth
                            maxWidth = labelWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_field_label)
                    }
                    label {
                        withStyle {
                            width = typeWidth
                            maxWidth = typeWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_field_type)
                    }
                    label {
                        withStyle {
                            width = cardinalityWidth
                            maxWidth = cardinalityWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_field_cardinality)
                    }
                    label {
                        withStyle {
                            width = valuesetWidth
                            maxWidth = valuesetWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_fields_valueset)
                    }
                    label {
                        withStyle {
                            width = stylepatternWidth
                            maxWidth = stylepatternWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_fields_stylepattern)
                    }
                    label {
                        withStyle {
                            width = selectedbydefaultWidth
                            maxWidth = selectedbydefaultWidth
                        }
                        text(CsawareMessagesObject.get().system_depend_fields_selectedbydefault)
                    }
                }
                currentFields.forEach { field ->
                    div {
                        addClass("form-inline")
                        div {
                            addClass("form-control")
                            withElement {
                                style.width = idWidth
                                style.maxWidth = idWidth
                                setAttribute("readOnly", "true")
                            }
                            text(field.id)
                            val usageCount = count[field.id] ?: 0
                            if (usageCount > 0) {
                                sup {
                                    addClass("badge badge-pill bg-warning")
                                    text("$usageCount")
                                }
                            }

                        }
                        input {
                            addClass("form-control")
                            withElement {
                                value = field.label
                                type = "text"
                                style.width = labelWidth
                                style.maxWidth = labelWidth
                                onchange = {
                                    if (value.isNotBlank()) {
                                        updateField(current(field).copy(label = value.trim()))
                                    }
                                }
                            }
                        }
                        select {
                            addClass("form-control")
                            withElement {
                                value = field.type.name
                                style.width = typeWidth
                                style.maxWidth = typeWidth
                                onchange = { updateField(current(field).copy(type = FieldType.parse(value))) }
                            }
                            FieldType.entries.forEach {
                                option {
                                    withElement {
                                        value = it.name
                                        selected = (it == field.type)
                                    }
                                    text(it.name)
                                }
                            }
                        }
                        select {
                            addClass("form-control")
                            withElement {
                                value = field.cardinality.name
                                style.width = cardinalityWidth
                                style.maxWidth = cardinalityWidth
                                onchange = { updateField(current(field).copy(cardinality = Cardinality.parse(value))) }
                            }
                            Cardinality.entries.forEach {
                                option {
                                    withElement {
                                        value = it.name
                                        selected = (it == field.cardinality)
                                    }
                                    text(CsawareMessagesObject.get().system_depend_field_cardinality_enum(it))
                                }
                            }
                        }
                        if (field.type != FieldType.DEPENDENCY) {
                            select {
                                addClass("form-control")
                                withElement {
                                    value = field.valueSet ?: noValueSet
                                    style.width = valuesetWidth
                                    style.maxWidth = valuesetWidth
                                    onchange = {
                                        updateField(current(field).copy(valueSet = if (value == noValueSet) null else value))
                                    }
                                }
                                option {
                                    withElement {
                                        value = noValueSet
                                        selected = (field.valueSet == null)
                                    }
                                }

                                modelConfig.data.valueSets.keys.forEach {
                                    option {
                                        withElement {
                                            value = it
                                            selected = (it == field.valueSet)
                                        }
                                        text(it)
                                    }
                                }
                            }
                        } else {
                            div {
                                withElement {
                                    style.width = valuesetWidth
                                    style.maxWidth = valuesetWidth
                                }
                            }
                        }
                        if (field.type == FieldType.DEPENDENCY) {
                            input {
                                addClass("form-control")
                                withElement {
                                    value = field.dashStylePattern
                                    type = "text"
                                    style.width = stylepatternWidth
                                    style.maxWidth = stylepatternWidth
                                    onchange = {
                                        updateField(current(field).copy(dashStylePattern = value))
                                    }
                                }
                            }
                        } else {
                            div {
                                withElement {
                                    style.width = stylepatternWidth
                                    style.maxWidth = stylepatternWidth
                                }
                            }
                        }
                        if (field.type == FieldType.DEPENDENCY) {
                            input {
                                addClass("form-check")
                                withElement {
                                    checked = field.selectedByDefault
                                    type = "checkbox"
                                    style.width = selectedbydefaultWidth
                                    style.maxWidth = selectedbydefaultWidth
                                    onchange = {
                                        updateField(current(field).copy(selectedByDefault = checked))
                                    }
                                }
                            }
                        } else {
                            div {
                                withElement {
                                    style.width = selectedbydefaultWidth
                                    style.maxWidth = selectedbydefaultWidth
                                }
                            }
                        }
                        span {
                            addClass("btn-group")
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        moveField(field, -1)
                                        rerender()
                                    }
                                }
                                i {
                                    addClass("fas fa-arrow-up")
                                }
                            }
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        moveField(field, +1)
                                        rerender()
                                    }
                                }
                                i {
                                    addClass("fas fa-arrow-down")
                                }
                            }
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        removeField(field)
                                        rerender()
                                    }
                                }
                                i {
                                    addClass("fas fa-trash")
                                }
                            }
                        }

                    }

                }
                div {
                    addClass("form-inline mt-1")
                    val idInput = input {
                        addClass("form-control")
                        withElement {
                            value = ""
                            type = "text"
                            style.width = idWidth
                            style.maxWidth = idWidth
                        }
                    }.element
                    val labelInput = input {
                        addClass("form-control")
                        withElement {
                            value = ""
                            type = "text"
                            style.width = labelWidth
                            style.maxWidth = labelWidth
                        }
                    }.element
                    val typeInput = select {
                        addClass("form-control")
                        withElement {
                            style.width = typeWidth
                            style.maxWidth = typeWidth
                        }
                        FieldType.entries.forEach {
                            option {
                                element.value = it.name
                                text(it.name)
                            }
                        }
                    }.element
                    val cardinalityInput = select {
                        addClass("form-control")
                        withElement {
                            value = Cardinality.One.name
                            style.width = cardinalityWidth
                            style.maxWidth = cardinalityWidth
                        }
                        Cardinality.entries.forEach {
                            option {
                                withElement {
                                    value = it.name
                                    selected = (it == Cardinality.One)
                                }
                                text(CsawareMessagesObject.get().system_depend_field_cardinality_enum(it))
                            }
                        }
                    }.element
                    val valuesetInput = select {
                        addClass("form-control")
                        withElement {
                            value = noValueSet
                            style.width = valuesetWidth
                            style.maxWidth = valuesetWidth
                        }
                        option {
                            withElement {
                                value = noValueSet
                                selected = true
                            }
                        }
                        modelConfig.data.valueSets.keys.forEach {
                            option {
                                withElement {
                                    value = it
                                }
                                text(it)
                            }
                        }
                    }.element
                    div {
                        withElement {
                            style.width = stylepatternWidth
                            style.maxWidth = stylepatternWidth
                        }
                    }
                    div {
                        withElement {
                            style.width = selectedbydefaultWidth
                            style.maxWidth = selectedbydefaultWidth
                        }
                    }
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                val id = idInput.value
                                val label = labelInput.value
                                val type = FieldType.parse(typeInput.value)
                                val cardinality = Cardinality.parse(cardinalityInput.value)
                                val valueSet = if (valuesetInput.value == noValueSet) null else valuesetInput.value
                                if (id.isNotBlank() && label.isNotBlank()) {
                                    if (id !in currentFields.map { it.id }) {
                                        updateField(
                                            if (type == FieldType.DEPENDENCY)
                                                SystemGraphField(id, label, type, cardinality)
                                            else
                                                SystemGraphField(id, label, type, cardinality, valueSet)
                                        )
                                        rerender()
                                    }
                                }
                            }
                        }
                        i {
                            addClass("fas fa-plus")
                        }
                    }

                }

            }
        }
    }

}