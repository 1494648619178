package csaware.ecosystem

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import kafffe.bootstrap.navigation.NavContainer
import kafffe.bootstrap.navigation.NavDropdown
import kafffe.bootstrap.navigation.NavItem
import kafffe.core.Model
import kafffe.core.ModelChangeListener
import kafffe.core.NavigationElement
import kafffe.core.NavigationPath
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier

class EcoSystemsDropdown(navElement: NavigationElement) : NavDropdown(
    navElement,
    i18nText(CsawareMessages::eco_system),
    "fas fa-seedling",
    dropdownClasses = "bg-light"
) {
    private val ecoSystemsModel = CsawareServices.ecoSystems.ecoSystemsModel
    init {
        ecoSystemsModel.listeners.add(ModelChangeListener {
            refreshDropdownItems()
        })
        refreshDropdownItems()
    }

    private fun refreshDropdownItems() {
        removeAllChildren()
        for ((id, name, _) in ecoSystemsModel.data) {
            NavItem(
                navigationElement,
                Model.of("$name ($id)"),
                NavigationPath.fromString("root/ecoSystem/$id"),
                "fas fa-seedling"
            ).also {
                it.cssClassModifier("dropdown-item")
                this.addChild(it)
            }
        }
        rerender()
    }
}

fun NavContainer.ecoSystemDropdown() = EcoSystemsDropdown(navigationTarget).also { addChild(it) }