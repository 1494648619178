package csaware.messages

import kafffe.messages.Messages_it

open class CsawareMessages_it : Messages_it(), CsawareMessages {
    override val translation_automatic: String = "Traduzione automatica"
    override val settings: String = "Impostazioni"
    override val nav_overview = "Panoramica"
    override val nav_threats = "Minacce"
    override val nav_threatsClosed = "Minacce Risolte"
    override val nav_config = "Configurazione"
    override val threat_group = "Gruppo"
    override val threat_group_all = "Tutte"
    override val threat_nobody = "Nobody"
    override val threat_state = "Stato"
    override val threat_state_initator = "Chi"
    override val threat_state_history = "Storico"
    override val threat_state_comment = "Commento"
    override val threat_course_of_action = "Sequenza delle azioni"
    override val threat_where = "Dove"
    override val threat_assignee = "Assegnatario"

    override val environment_name = "Nome"

    override val threat_observed_data_objects = "Dati Analizzati"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Tipo"
    override val threat_observed_data_data = "Dati"

    override val threat_subtype = "Sottotipo"
    override val threat_firstObserved = "Prima osservazione"
    override val threat_endActive = "Risolto il"
    override val threat_newState = "Cambio Stato"
    override val threat_selfHealingConfirm = "Conferma il Self Healing"

    override val threat_topTitle = "Principali Minacce"

    override val severity = "Severità"
    override val count = "Numerosità"

    override val threat_level_5_name = "Critica"
    override val threat_level_5_text = "Un attacco è previsto imminentemente."
    override val threat_level_4_name = "Severa"
    override val threat_level_4_text = "Un attacco è altamente probabile."
    override val threat_level_3_name = "Elevata"
    override val threat_level_3_text = "Un attacco è probabilie."
    override val threat_level_2_name = "Moderata"
    override val threat_level_2_text = "Un attacco è possibile, ma non probabile."
    override val threat_level_1_name = "Basso"
    override val threat_level_1_text = "Un attacco non è probabile."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Attiva"
    override val ThreatState_Resolved = "Risolta"
    override val ThreatState_Ignored = "Ignorata"
    override val ThreatState_HealingAwaitDecision = "Necessaria una decisione per Self Healing "
    override val ThreatState_HealingAccepted = "Accetta Self Healing"
    override val ThreatState_HealingDeclined = "Rifiuta Self Healing"
    override val ThreatState_Healed = "Self Healing Effettuato"
    override val ThreatState_HealingFailed = "Self Healing Fallito"
    override val ThreatState_HealingInProgress = "Self Healed in corso"
    override val ThreatState_Unknown = "Sconosciuto"

    override val user_management = "Gestione Utente"
    override val user_edit = "Modifica Utente"
    override val user_new = "Nuovo Utente"
    override val user_subject = "Oggetto"
    override val user_email = "Email"
    override val user_firstName = "Nome"
    override val user_lastName = "Cognome"
    override val user_fullname = "Nome"
    override val user_roles = "Ruoli"
    override val user_action_import = "Importa"
    override val user_action_export = "Esporta"

    override val user_preferences_edit = "Preferenze dell'interfaccia utente"
    override val user_preferences_dataPresentationLanguage = "Linguaggio di presentazione dei dati"
    override val user_preferences_uiLanguage ="Lingua dell'interfaccia utente"

    override val system_preferences_edit = "Preferenze di Sistema"
    override val system_preferences_accountDataPresentationLanguage = "Lingua di presentazione dei dati predefinita"
    override val system_preferences_accountUiLanguage ="Lingua dell'interfaccia utente predefinita"

    override val informationshare_management = "Condivisione delle informazioni"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Modifica la Condivisione delle Informazioni"
    override val informationshare_id = "Id"
    override val informationshare_description = "Descrizione"
    override val informationshare_state = "Stato"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Ultima modifica"
    override val informationshare_initiator = "Chi"
    override val informationshare_history = "Storico"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Conferma condivisione"
    override val ShareState_Accept = "accetta"
    override val ShareState_Decline = "rifiuta"
    override val ShareState_Unknown = "non definita"

    override val system_dependencies = "Sistema"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Nome"
    override val system_depend_description= "Descrizione"
    override val system_depend_connectedFrom= "Connesso da"
    override val system_depend_connectedTo= "Connesso a"
    override val system_depend_infoflow= "Flusso di informazioni"
    override val system_depend_category= "Categoria"
    override val system_depend_fields= "Campi"
    override val system_depend_fields_valueset = "Insieme di Valori"
    override val system_depend_fields_valueset_values = "Valori"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Layout"
    override val system_depend_layoutDirection = "Layout Direzione"
    override val system_depend_layout_horizontal = "Orizzontale"
    override val system_depend_layout_vertical = "Verticale"
    override val system_depend_layout_roots = "Roots"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Spacing"

    override val system_depend_action_search = "Cerca Risorse di Sistema"
    override val system_depend_action_new = "Crea Nuovo"
    override val system_depend_action_edit = "Modifica"
    override val system_depend_action_delete = "Cancella"
    override val system_depend_action_delete_confirm_title = "Cancella Risorse di Sistema"
    override val system_depend_action_config = "Configurazione"
    override val system_depend_action_import = "Importa"
    override val system_depend_action_export = "Esporta"
    override val system_depend_action_config_import = "Configurazione Importa"
    override val system_depend_action_config_export = "Configurazione Esporta"
    override val system_depend_action_print = "Stampa"

    override val system_depend_action_backup_or_restore = "Backup o Ripristino"
    override val system_depend_backup_title = "Backup delle Risorse di Sistema"
    override val system_depend_backup_create_new = "Nuovo backup"
    override val system_depend_backup_created = "Creato"
    override val system_depend_backup_comment = "Commento"
    override val system_depend_backup_nodes_not_in_current = "Non nel corrente"
    override val system_depend_backup_nodes_in_current_not_in_this = "Non in questo"
    override val system_depend_backup_nodes_changed = "Modificato"
    override val system_depend_restore_state = "Modifica"
    override val system_depend_restore_operation = "Operazione"
    override val system_depend_restore_operation_remove = "Rimuovi"
    override val system_depend_restore_operation_restore = "Ripristina"
    override val system_depend_restore_revert_all_nodes = "Ripristina tutti i Nodi di Sistema"
    override val system_depend_restore_revert_configuration = "Ripristina la Configurazione delle Dipendenze di Sistema"

    override val system_depend_label_all = "Tutto"

    override val system_depend_field_id = "Id"
    override val system_depend_field_label = "Nome"
    override val system_depend_field_type = "Tipo"
    override val system_depend_field_cardinality = "Cardinalità "
    override val system_depend_field_add = "Includi campo"
    override val system_depend_fields_config = "Configurazione dei campi.."
    override val system_depend_field_validation_error_txt = "'%value%' non è valido per '%type%'"

    override val sign_out = "Check Out"
    override val sign_out_disclaimer = """
        |The CS-AWARE Visualisation session as been cleared,  
        |you will have to logout of the external Authorisation Provider,  
        |if you do not want to be automatically signed in again. 
        |""".trimMargin()
    override val sign_out_link="Go to Your Authorisation Provider to Sign Out"

    override val mail_dialog_title = "Send Email"
    override val mail_to = "To"
    override val mail_from = "From"
    override val mail_subject = "Subject"
    override val mail_body = "Body"
    override val mail_send = "Send"

    override val socialmedia_reader : String = "Social media"
    override val socialmedia_reader_filter_readstate_both = "Sia letto che non letto"
    override val socialmedia_reader_filter_readstate_not_read = "Solo non letto"
    override val socialmedia_reader_filter_readstate_read = "Solo letta"

    override val socialmedia_reader_read_on_source = "Leggi la fonte"
    override val socialmedia_createdAt : String = "Creato in data"
    override val socialmedia_keywords : String = "Parole chiave"
    override val socialmedia_text : String = "Testo"
    override val socialmedia_account : String = "Account"
    override val socialmedia_id:String = "Id"

    override val socialmedia_system_where = "Relativo a"

    override val socialmedia_account_included = "Incluso"
    override val socialmedia_management_title = "Gestione dei social media"
    override val socialmedia_account_edit = "Modifica"
    override val socialmedia_account_new = "Nuovo"
    override val socialmedia_account_mediaType = "Tipo"
    override val socialmedia_account_collectorState = "Raccoglitore"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Nome"
    override val socialmedia_account_displayName = "Nome del display"
    override val socialmedia_account_description= "Descrizione"
    override val socialmedia_account_lastCollected = "Ultima raccolta"

    override val socialmedia_account_twitter_new = "Nuova raccolta di account X"
    override val socialmedia_account_include = "Includere la raccolta dei conti"
    override val socialmedia_account_exclude = "Escludere la riscossione dell'account"

    override val socialmedia_account_action_export = "Esportazione"
    override val socialmedia_account_action_import = "Importa"
    override val socialmedia_account_action_include_page = "Includi la raccolta per gli account nella pagina"
    override val socialmedia_account_action_exclude_page = "Escludi la raccolta per gli account nella pagina"
    override val socialmedia_create_threatobservation = "Crea rapporto sulle minacce"

    override val policy = "Policy"
    override val policy_edit = "Edit policy"
    override val policy_title = "Title"
    override val policy_purpose = "Purpose"
    override val policy_elements = "Elements"
    override val policy_need = "Need"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "References"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences = "System resource references"
    override val policy_new  = "Create policy"
    override val policy_createdAt = "Created at"
    override val policy_createdBy = "Created by"
    override val policy_updatedAt = "Updated at"
    override val policy_updatedBy = "Updateted by"

    override val policy_state  = "Stato"
    override val policy_state_draft = "Bozza"
    override val policy_state_approved = "Approvato"
    override val policy_state_draft_with_approved = "Bozza con versione approvata"
    override val policy_state_obsolete = "Obsoleto"
    override val policy_state_unknown = "Sconosciute"

    override val policy_type_organisation =  "Organizzazione"
    override val policy_type_template = "Modello"

    override val policy_comment_comment = "Commento"
    override val policy_comment_add = "Aggiungi commento"
    override val policy_comment_edit = "Modifica commento"
    override val policy_comment_respond = "Rispondi"
    override val policy_comment_delete = "Elimina commento"

    override val policy_approve_comment_add = "Aggiungi commento di approvazione"

    override val policy_history = "Storia"

    override val policy_action_edit = "Modifica"
    override val policy_action_clone = "Clona"
    override val policy_action_approve = "Approva"
    override val policy_action_export = "Esporta"

    override val policy_action_ai_import = "Politica di importazione AI.."
    override val policy_import_extract_text = "Estrarre testo dal documento (file)"

    override val policy_config  = "Configurazione di tag e tipi di relazione"
    override val policy_relation_type = "Tipo di relazione"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Nome"
    override val policy_relation_reversed = "Relazione inversa"
    override val policy_template_action_edit= "Nuovo dal modello"

    override val markdown_bold = "Grassetto"
    override val markdown_italic = "Corsivo"
    override val markdown_strikethrough = "Barrato"
    override val markdown_heading = "Intestazione"
    override val markdown_heading_smaller = "Titolo più piccolo"
    override val markdown_heading_bigger = "Titolo più grande"
    override val markdown_heading_1x = "Intestazione 1"
    override val markdown_heading_2x = "Intestazione 2"
    override val markdown_heading_3x = "Intestazione 3"
    override val markdown_code = "Codice"
    override val markdown_quote = "Citazione"
    override val markdown_unordered_list = "Elenco non ordinato"
    override val markdown_ordered_list = "Elenco ordinato"
    override val markdown_clean_block = "Blocco pulito"
    override val markdown_link = "Inserisci link"
    override val markdown_image = "Inserire immagine"
    override val markdown_mde_table = "Inserire tabella"
    override val markdown_horizontal_rule = "Regola orizzontale"
    override val markdown_preview = "Alterna anteprima"
    override val markdown_side_by_side = "Vista laterale"
    override val markdown_fullscreen = "Schermo intero"
    override val markdown_guide = "Guida"
    override val markdown_undo = "Annullare"
    override val markdown_redo = "Rifare"
}