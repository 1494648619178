package csaware.messages

import kafffe.messages.Messages_en

open class CsawareMessages_en : Messages_en(), CsawareMessages {
    override val translation_automatic: String = "Automatic translation"
    override val settings: String = "Settings"
    override val nav_overview = "Overview"
    override val nav_threats = "Threats"
    override val nav_threatsClosed = "Threats Closed"
    override val nav_config = "Configuration"
    override val threat_group = "Group"

    override val environment_name = "Name"

    override val threat_group_all = "All"
    override val threat_nobody = "Nobody"
    override val threat_state = "State"
    override val threat_state_initator = "Who"
    override val threat_state_history = "History"
    override val threat_state_comment = "Comment"
    override val threat_course_of_action = "Course of Action"
    override val threat_where = "Where"
    override val threat_assignee = "Assigned to"

    override val threat_observed_data_objects = "Observed Data"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Type"
    override val threat_observed_data_data = "Data"

    override val threat_subtype = "Subtype"
    override val threat_firstObserved = "First observed"
    override val threat_endActive = "Closed at"
    override val threat_newState = "Change State"
    override val threat_selfHealingConfirm = "Confirm Self Healing"

    override val threat_topTitle = "Top Threats"

    override val severity = "Severity"
    override val count = "Count"

    override val threat_level_5_name = "Critical"
    override val threat_level_5_text = "An attack is expected imminently."
    override val threat_level_4_name = "Severe"
    override val threat_level_4_text = "An attack is highly likely."
    override val threat_level_3_name = "Substantial"
    override val threat_level_3_text = "An attack is a strong possibility."
    override val threat_level_2_name = "Moderate"
    override val threat_level_2_text = "An attack is possible, but not likely."
    override val threat_level_1_name = "Low"
    override val threat_level_1_text = "An attack is unlikely."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Active"
    override val ThreatState_Resolved = "Resolved"
    override val ThreatState_Ignored = "Ignored"
    override val ThreatState_HealingAwaitDecision = "Self Healing needs descision"
    override val ThreatState_HealingAccepted = "Self Healing accept"
    override val ThreatState_HealingDeclined = "Self Healing decline"
    override val ThreatState_Healed = "Self Healing Done"
    override val ThreatState_HealingFailed = "Self Healing Failed"
    override val ThreatState_HealingInProgress = "Being Self Healed"
    override val ThreatState_Unknown = "unknown"

    override val user_management = "User Management"
    override val user_edit = "User Edit"
    override val user_new = "New user"
    override val user_subject = "Subject"
    override val user_email = "Email"
    override val user_firstName = "First Name"
    override val user_lastName = "Last Name"
    override val user_fullname  = "Name"
    override val user_roles = "Roles"
    override val user_action_import = "Import"
    override val user_action_export = "Export"

    override val user_preferences_edit ="UI Preferences"
    override val user_preferences_dataPresentationLanguage = "Data presentation language"
    override val user_preferences_uiLanguage ="User interface languge"

    override val system_preferences_edit= "System preferences"
    override val system_preferences_accountDataPresentationLanguage = "Default data presentation language"
    override val system_preferences_accountUiLanguage ="Default user interface languge"

    override val informationshare_management = "Information Sharing"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Edit Information Sharing"
    override val informationshare_id = "Id"
    override val informationshare_description = "Description"
    override val informationshare_state = "State"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Last modified"
    override val informationshare_initiator = "Who"
    override val informationshare_history = "History"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Confirm sharing"
    override val ShareState_Accept = "accept"
    override val ShareState_Decline = "decline"
    override val ShareState_Unknown = "unknown"

    override val system_dependencies = "System"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Name"
    override val system_depend_description= "Description"
    override val system_depend_connectedFrom= "Connected from"
    override val system_depend_connectedTo= "Connects to"
    override val system_depend_infoflow= "Infoflow"
    override val system_depend_category= "Category"
    override val system_depend_fields= "Fields"
    override val system_depend_fields_valueset = "Field Value Set"
    override val system_depend_fields_valueset_values = "Values"
    override val system_depend_node_types = "Node Types"
    override val system_depend_layout = "Layout"
    override val system_depend_layoutDirection = "Layout Direction"
    override val system_depend_layout_horizontal = "Horizontal"
    override val system_depend_layout_vertical = "Vertical"
    override val system_depend_layout_roots = "Roots"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Spacing"

    override val system_depend_action_search = "Search System Resource"
    override val system_depend_action_new = "Create New"
    override val system_depend_action_edit = "Edit"
    override val system_depend_action_delete = "Delete"
    override val system_depend_action_delete_confirm_title = "Delete System Resource"
    override val system_depend_action_config = "Configuration"
    override val system_depend_action_import = "Import"
    override val system_depend_action_export = "Export"
    override val system_depend_action_config_import = "Configuration Import"
    override val system_depend_action_config_export = "Configuration Export"
    override val system_depend_action_print = "Print"

    override val system_depend_action_backup_or_restore = "Backup or Restore"
    override val system_depend_backup_title = "System Resources Backup"
    override val system_depend_backup_create_new = "New backup"
    override val system_depend_backup_created = "Created"
    override val system_depend_backup_comment = "Comment"
    override val system_depend_backup_nodes_not_in_current = "Not in current"
    override val system_depend_backup_nodes_in_current_not_in_this = "Not in this"
    override val system_depend_backup_nodes_changed = "Changed"
    override val system_depend_restore_state = "Change"
    override val system_depend_restore_operation = "Operation"
    override val system_depend_restore_operation_remove = "Remove"
    override val system_depend_restore_operation_restore = "Restore"
    override val system_depend_restore_revert_all_nodes = "Restore all System Nodes"
    override val system_depend_restore_revert_configuration = "Restore System Dependencies Configuration"

    override val system_depend_label_all = "All"

    override val system_depend_field_id = "ID"
    override val system_depend_field_label = "Label"
    override val system_depend_field_type = "Type"
    override val system_depend_field_cardinality = "Cardinality"
    override val system_depend_field_add = "Add Field"
    override val system_depend_fields_config = "Field Configuration.."
    override val system_depend_field_validation_error_txt = "'%value%' is not valid for '%type%'"

    override val sign_out = "Check Out"
    override val sign_out_disclaimer = """
        |The CS-AWARE Visualisation session as been cleared,  
        |you will have to logout of the external Authorisation Provider,  
        |if you do not want to be automatically signed in again. 
        |""".trimMargin()
    override val sign_out_link="Go to Your Authorisation Provider to Sign Out"

    override val mail_dialog_title = "Send Email"
    override val mail_to = "To"
    override val mail_from = "From"
    override val mail_subject = "Subject"
    override val mail_body = "Body"
    override val mail_send = "Send"

    override val socialmedia_reader : String = "Socialmedia"
    override val socialmedia_reader_filter_readstate_both = "Both read and unread"
    override val socialmedia_reader_filter_readstate_not_read = "Only not read"
    override val socialmedia_reader_filter_readstate_read = "Only read"

    override val socialmedia_reader_read_on_source  = "Read source"
    override val socialmedia_createdAt : String = "Created at"
    override val socialmedia_keywords : String = "Keywords"
    override val socialmedia_text : String = "Text"
    override val socialmedia_account : String = "Account"
    override val socialmedia_id:String = "Id"

    override  val socialmedia_system_where  = "Relates to"

    override val socialmedia_account_included = "Included"
    override val socialmedia_management_title = "Socialmedia Management"
    override val socialmedia_account_edit = "Edit"
    override val socialmedia_account_new = "New"
    override val socialmedia_account_mediaType = "Type"
    override val socialmedia_account_collectorState = "Collector"
    override val socialmedia_account_id = "Id"
    override val socialmedia_account_name = "Name"
    override val socialmedia_account_displayName = "Display name"
    override val socialmedia_account_description= "Description"
    override val socialmedia_account_lastCollected = "Last collected"

    override val socialmedia_account_twitter_new = "New X Account collection"
    override val socialmedia_account_include = "Include account collection"
    override val socialmedia_account_exclude = "Exclude account collection"

    override val socialmedia_account_action_export = "Export"
    override val socialmedia_account_action_import = "Import"
    override val socialmedia_account_action_include_page = "Include collection for accounts on the page"
    override val socialmedia_account_action_exclude_page = "Exclude collection for accounts on the page"

    override val socialmedia_create_threatobservation = "Create Threat Report"

    override val policy = "Policy"
    override val policy_edit = "Edit policy"
    override val policy_title: String = "Title"
    override val policy_purpose = "Purpose"
    override val policy_elements = "Elements"
    override val policy_need = "Need"
    override val policy_roles_responsibilities = "Role and responsibilities"
    override val policy_references = "References"
    override val policy_tags = "Tags"
    override val policy_systemNodeReferences = "System resource references"
    override val policy_new  = "Create policy"
    override val policy_createdAt = "Created at"
    override val policy_createdBy = "Created by"
    override val policy_updatedAt = "Updated at"
    override val policy_updatedBy = "Updateted by"

    override val policy_state  = "State"
    override val policy_state_draft  = "Draft"
    override val policy_state_approved  = "Approved"
    override val policy_state_draft_with_approved = "Draft with approved version"
    override val policy_state_obsolete = "Obsolete"
    override val policy_state_unknown = "Unknown"

    override val policy_type_organisation =  "Organisation"
    override val policy_type_template = "Template"

    override val policy_comment_comment = "Comment"
    override val policy_comment_add = "Add comment"
    override val policy_comment_edit = "Edit comment"
    override val policy_comment_respond = "Respond"
    override val policy_comment_delete = "Delete comment"

    override val policy_approve_comment_add = "Add approve comment"

    override val policy_history = "History"

    override val policy_action_edit = "Edit"
    override val policy_action_clone = "Clone"
    override val policy_action_approve = "Approve"
    override val policy_action_export = "Export"

    override val policy_action_ai_import = "AI import policy.."
    override val policy_import_extract_text = "Extract text from document (file)"

    override val policy_config  = "Configuration of tags and relation types"
    override val policy_relation_type = "Relation Type"
    override val policy_relation_id = "Id"
    override val policy_relation_name = "Name"
    override val policy_relation_reversed = "Reversed Relation"
    override val policy_template_action_edit = "New from the template"

    override val markdown_bold            = "Bold"
    override val markdown_italic          = "Italic"
    override val markdown_strikethrough   = "Strikethrough"
    override val markdown_heading         = "Heading"
    override val markdown_heading_smaller = "Heading smaller"
    override val markdown_heading_bigger  = "Heading bigger"
    override val markdown_heading_1x       = "Heading 1"
    override val markdown_heading_2x       = "Heading 2"
    override val markdown_heading_3x       = "Heading 3"
    override val markdown_code            = "Code"
    override val markdown_quote           = "Quote"
    override val markdown_unordered_list  = "Unordered list"
    override val markdown_ordered_list    = "Ordered list"
    override val markdown_clean_block     = "Clean _block"
    override val markdown_link            = "Insert Link"
    override val markdown_image           = "Insert Image"
    override val markdown_mde_table       = "Insert table"
    override val markdown_horizontal_rule = "Horizontal rule"
    override val markdown_preview         = "Toggle preview"
    override val markdown_side_by_side    = "Side by side view"
    override val markdown_fullscreen      = "Fullscreen"
    override val markdown_guide           = "Guide"
    override val markdown_undo            = "Undo"
    override val markdown_redo            = "Redo"
}

