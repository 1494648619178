package csaware.policy

import csaware.main.navigateTo
import csaware.utilities.markdown.MarkdownRender
import dk.rheasoft.csaware.api.PolicyEditHistoryElement
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier


class PolicyHistoryDlg(
    model: Model<PolicyEditHistoryElement>,
    private val namesFromIds:List<String>
) : FormDialog<PolicyEditHistoryElement>(Model.of("${model.data.policyData.title} ${model.data.createdAt}"),model) {
    private val linkClass: String = "link-primary"
    init {
        setModelChangedRerender()
        cssClassModifier("hgap-3 vgap-3")
        size = ModalSize.medium

        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "45vw"
            width = "700px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-light"))


    }


    override fun KafffeHtmlBase.kafffeHtml() = form{
        div {
            with(model.data) {
                h2 { text(policyData.title) }

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_purpose) }
                add(MarkdownRender(policyData.purpose).html)

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_elements) }
                add(MarkdownRender(policyData.elements).html.apply { style.maxHeight= "none" } )

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_need) }
                add(MarkdownRender(policyData.need).html.apply { style.maxHeight= "none" })

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_roles_responsibilities) }
                add(MarkdownRender(policyData.rolesResponsibilities).html.apply { style.maxHeight= "none" })

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_references) }
                add(MarkdownRender(policyData.references).html.apply { style.maxHeight= "none" })

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_systemNodeReferences) }
                add( div{
                    namesFromIds.forEachIndexed{ index: Int, name: String ->
                        a {
                            text(name)
                            withElement {
                                className = linkClass
                                style.whiteSpace = "nowrap"
                                href="#"
                                onclick = {
                                    onclick(name)
                                }
                            }
                        }
                        if (index < namesFromIds.size - 1) {
                            text(", ")
                        }
                    }
                })

                h3 { text(csaware.messages.CsawareMessagesObject.get().policy_tags) }
                text(policyData.tags.joinToString(", "))

                h3 { text(csaware.messages.CsawareMessagesObject.get().accessLevel) }
                add(MarkdownRender(csaware.messages.CsawareMessagesObject.get().accessLevelName(accessLevel)).html)
            }
        }
    }
    fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}

