package dk.rheasoft.csaware.api.incident

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

/**
 * State of an Incident with regards to BCDR and SelfHealing
 */
@Serializable(with = BcdrSelfHealingState.Serializer::class)
enum class BcdrSelfHealingState(private val value: String) {
    Unknown(""),
    NeedsUserAccept("needs_user_accept"),
    UserAccepted("user_accepted"),
    UserDeclined("user_declined"),
    Processing("processing"),
    Done("done"),
    PartialDone("partial_done"),
    Skipped("skipped");
    fun toInternal(): String = value

    companion object {
        /**
         * Convert from stored int
         */
        fun fromInternal(value: String) = entries.firstOrNull { it.value == value } ?: Unknown
    }

    @OptIn(ExperimentalSerializationApi::class)
    object Serializer : KSerializer<BcdrSelfHealingState> {
        override val descriptor: SerialDescriptor =
            PrimitiveSerialDescriptor("dk.rheasoft.csaware.api.incident.BcdrSelfHealingState", PrimitiveKind.INT)

        override fun serialize(encoder: Encoder, value: BcdrSelfHealingState) {
            encoder.encodeString(value.toInternal())
        }


        override fun deserialize(decoder: Decoder): BcdrSelfHealingState {
            return BcdrSelfHealingState.fromInternal(decoder.decodeString())
        }
    }
}

