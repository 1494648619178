package csaware.comm


import csaware.main.UserInformation
import csaware.utilities.UUID
import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.policy.PolicyFilter
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import encodeURIComponent
import kotlinx.datetime.Clock
import org.w3c.files.File

open class PolicyBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {
    private fun encodeListParameter(list: Iterable<String>) = list.joinToString(",") { encodeURIComponent(it) }

    /**
     * Gets the tags actually sued by the current policies.
     */
    fun getUsedPolicyTags(receiver: (List<String>) -> Unit) {
        getTxt("/policy/tag/used"){
            val x: List<String> = JsonUtilSerialization.json.decodeFromString(it)
            receiver(x)
        }
    }

    //Not yet in Datrix
    fun getPolicyTemplates(offset: Int, pagesize: Int, filter: PolicyFilter, receiver: (QueryResult<Policy>) -> Unit ){
        val parameters = mapOf(
            "offset" to offset,
            "limit" to pagesize,
            "filter" to filter.filter,
            "nodes" to encodeListParameter(filter.nodes),
            "tags" to encodeListParameter(filter.tags),
        )
        val queryParams = parameters.entries.joinToString("&") { "${it.key}=${it.value}" }

        getTxt("/policy/templates/get?$queryParams"){
            val x: QueryResult<Policy> = Policy.fromQueryResultJson(it)
            receiver(x)
        }
    }
    fun getPolicies(offset: Int, pagesize: Int, filter: PolicyFilter, receiver: (QueryResult<Policy>) -> Unit ){  //, filter: String) {
        val parameters = mapOf(
            "offset" to offset,
            "limit" to pagesize,
            "filter" to filter.filter,
            "nodes" to encodeListParameter(filter.nodes),
            "tags" to encodeListParameter(filter.tags),
            "states" to encodeListParameter(filter.states.map { it.name }),
        )
        val queryParams = parameters.entries.joinToString("&") { "${it.key}=${it.value}" }

        getTxt("/policy/policies/get?$queryParams"){
            val x: QueryResult<Policy> = Policy.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getPolicyById(policyId: String, receiver: (Policy) -> Unit ){
        getTxt("/policy/$policyId"){
            val x: Policy = Policy.fromJson(it)
            receiver(x)
        }
    }

    fun storePolicy(policy: Policy, receiver: (Policy) -> Unit) {
        sendJsonTxt("/policy/store", "POST", policy.toJsonString()) {
            val x: Policy = Policy.fromJson(it)
            receiver(x)
        }
    }

    fun deletePolicy(policy: Policy, receiver: () -> Unit) {
        sendJsonTxt("/policy/delete", "DELETE", policy.toJsonString()) {
            if(policy.state.equals(PolicyState.APPROVED)||policy.state.equals(PolicyState.DRAFT_WITH_APPROVED)) {
                policy.state = PolicyState.OBSOLETE
                sendJsonTxt("/policy/updatePolicyById", "POST", policy.toJsonString()){}
            }
            receiver()
        }
    }


    fun getCommentsToPolicy(policyId: String, receiver:(QueryResult<PolicyComment>) -> Unit ){
        getTxt("/policy/comments/$policyId"){
            val x: QueryResult<PolicyComment> = PolicyComment.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getAllHistoryToPolicy(policyId: String, receiver:(QueryResult<PolicyEditHistoryElement>) -> Unit ){
        getTxt("/policy/historyById/$policyId"){
            val x: QueryResult<PolicyEditHistoryElement> = PolicyEditHistoryElement.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getHistoryToPolicy(offset: Int, pagesize: Int, filter: PolicyFilter,policyId: String,orderBy:String ="", receiver: (QueryResult<PolicyEditHistoryElement>) -> Unit ) {  //, filter: String) {
        val parameters = mapOf(
            "offset" to offset,
            "limit" to pagesize,
            "filter" to filter.filter,
            "nodes" to encodeListParameter(filter.nodes),
            "tags" to encodeListParameter(filter.tags),
            "states" to encodeListParameter(filter.states.map { it.name }),
            "policyId" to policyId,
            "orderBy" to orderBy,
        )
        val queryParams = parameters.entries.joinToString("&") { "${it.key}=${it.value}" }
        getTxt("/policy/historyByIdAndFilter/get?$queryParams") {
            val x: QueryResult<PolicyEditHistoryElement> = PolicyEditHistoryElement.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun storePolicyComment(policyComment:PolicyComment, receiver: (PolicyComment) -> Unit) {
        sendJsonTxt("/policy/comment/store", "PUT", policyComment.toJsonString()) {
            val x: PolicyComment = PolicyComment.fromJson(it)
            receiver(x)
        }
    }

    fun storePolicyHistoryEditElement(policyEditHistoryElement: PolicyEditHistoryElement, receiver: (PolicyEditHistoryElement) -> Unit) {
        sendJsonTxt("/policy/history/store", "PUT", policyEditHistoryElement.toJsonString()) {
            val x: PolicyEditHistoryElement = PolicyEditHistoryElement.fromJson(it)
            receiver(x)
        }
    }

    fun deleteComment(policyComment: PolicyComment, receiver: () -> Unit) {
        sendJsonTxt("/policy/comment/delete", "DELETE", policyComment.toJsonString()) {
            receiver()
        }
    }

    fun deleteAllCommentsToPolicy(policy: Policy, receiver: () -> Unit) {
        sendJsonTxt("/policy/comment/deletePolicyComments", "DELETE", policy.toJsonString()) {
            receiver()
        }
    }

    fun approve(policyWithApproval: PolicyWithApproval, receiver:(InsertOrUpdatePolicyResult) -> Unit) {
       /* Test code - please leave it here, will be removed when no longer needed - Javi
        if (false){
            exceptionHandler(
                700,
                "Policy: ${policyWithApproval.policy.policyData.title}, was not approved. Please try again"
            )
        } else {*/
            sendJsonTxt("/policy/insertOrUpdatePolicy", "POST", policyWithApproval.policy.toJsonString()) {
                val x: InsertOrUpdatePolicyResult = InsertOrUpdatePolicyResult.fromJson(it)
                if (x.success) {
                    approveLocal(policyWithApproval)
                } else {
                    exceptionHandler(
                   700,
                   "Policy: ${policyWithApproval.policy.policyData.title}, was not approved. Please try again"
                    )
                }
                receiver(x)
            }
        }
    //}

    fun approveLocal(policyWithApproval: PolicyWithApproval) {
        policyWithApproval.policy.state = PolicyState.APPROVED
        storePolicy(policyWithApproval.policy) {
        }
        val apc = PolicyComment(
            id = UUID.generateUuid("approveComment--"),
            policyId = policyWithApproval.policy.id,
            responseToCommentId = null,
            comment = "Approve Comment: " + policyWithApproval.approvalComment,
            createdAt = Clock.System.now(),
            createdBy = UserInformation.current.email,
            updatedAt = Clock.System.now(),
            updatedBy = UserInformation.current.email,
        )
        storePolicyComment(apc) {}
    }
    fun extractText(file: File, receiver: (String) -> Unit) {
        sendFile("/doc_extract_text", "POST", file) {
            receiver(it)
        }
    }

    fun policyDataFromText(request:  PolicyDataExtractRequest , receiver: (PolicyDataExtractResponse) -> Unit) {
        sendJsonTxt("/policy/data_from_text", "POST", request.toJsonString()) {
            receiver(PolicyDataExtractResponse.fromJson(it))
        }
    }

}

