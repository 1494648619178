package csaware.kpi_statistics

import bootstrap.Collapse
import dk.rheasoft.csaware.api.statistics.StatisticCounter
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement

open class StatisticSectionView(
    private val titleModel: Model<String>,
    private val counterPairs: List<Pair<Model<String>, StatisticCounter>>,
) : KafffeComponent() {

    private var collapsed = true
    fun toggleCollapsed() {
        collapsed = !collapsed
    }

    private val counterViews: List<KafffeComponent> = counterPairs.map { (titleModel, counter) ->
        when (counter.countType) {
            StatisticCounter.CountType.DAILY -> addChild(CounterColumnChart(titleModel, counter, 60))
            StatisticCounter.CountType.WEEKLY -> addChild(CounterColumnChart(titleModel, counter, 52))
            StatisticCounter.CountType.MONTHLY -> addChild(CounterColumnChart(titleModel, counter, 12))
            StatisticCounter.CountType.TOTAL -> addChild(
                CounterColumnChart(
                    titleModel,
                    counter,
                    1
                )
            ) //TODO Total view should be different
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        lateinit var btn: HTMLButtonElement
        lateinit var section: HTMLDivElement
        return div {
            addClass("accordion-item")
            h2 {
                addClass("accordion-header")
                button {
                    addClass("accordion-button")
                    withElement {
                        type = "button"
                        btn = this
                        if (!collapsed) btn.removeClass("collapsed") else btn.addClass("collapsed")
                    }
                    text(titleModel.data)
                }
            }
            div {
                addClass("accordion-collapse accordion-body")
                addClass(if (collapsed) "collapsed" else "show")
                section = this.element
                for (child in children) {
                    add(child.html)
                }
            }
        val collapse = Collapse(section)
        btn.onclick = {
            toggleCollapsed()
            collapse.toggle()
            if (!collapsed) {
                btn.removeClass("collapsed")
            } else {
                btn.addClass("collapsed")
            }
        }
     }
    }

}