package csaware.anomalies

import csaware.messages.CsawareMessagesObject
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import dk.rheasoft.csaware.json.adapter.stix.*
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.textArea
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier

class AnomalyDetailsView(
    val anomalyWrapper: AnomalyWrapper,
    private val graphService: SystemDependencyService
) : KafffeComponent() {
    private val labelStrategy = CsawareMessagesObject.csawareMessageStrategy("anomaly_details_")
    val mainDetails = addChild(BootstrapForm<AnomalyWrapper>(Model.of(anomalyWrapper)).apply {
        labelStrategy = labelStrategy
        cssClassModifier("form")
        row(Model.of(anomalyWrapper.anomaly)) {
            col(ColWidth(ResponsiveSize.md, 6)) {
                cssClassModifier("hgap-3 vgap-3")
                group {
                    cssClassModifier("hgap-3 vgap-3")
                    readonly(Anomaly::type)
                    readonly(Anomaly::category)
                }
            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                cssClassModifier("hgap-3 vgap-3")
                textArea(Anomaly::description).apply {
                    lines = 8
                    readOnly = true
                }
            }
        }
        // May be tabs
        if (anomalyWrapper.anomaly.attributes.adapter.hasProperty("critical_asset")) {
            val criticalAsset = anomalyWrapper.anomaly.attributes.critical_asset
            row(Model.of(criticalAsset)) {
                legend("critical_asset")
                col(ColWidth(ResponsiveSize.md, 3)) {
                    cssClassModifier("hgap-3 vgap-3")
                    readonly(AnomalyCriticalAsset::type)
                }
                col(ColWidth(ResponsiveSize.md, 9)) {
                    val node = graphService.byId(criticalAsset.asset_identifier)
                    val asset = if (node == null) {
                        criticalAsset.asset_identifier
                    } else {
                        "${node.name} (${criticalAsset.asset_identifier})"
                    }
                    readonly(labelStrategy.label("asset_identifier"), Model.of(asset))
                }
            }
        }

        if (anomalyWrapper.anomaly.attributes.adapter.hasProperty("anomaly_details")) {
            val anomalyDetails = anomalyWrapper.anomaly.attributes.anomaly_details
            when (anomalyWrapper.anomaly.module) {
                AnomalyModule.LineGuard -> {
                    val details = anomalyWrapper.anomaly.attributes.lineguard_details
                    row(Model.of(details)) {
                        cssClassModifier("hgap-3 vgap-3")
                        legend("anomaly_details")
                        readonly(labelStrategy.label("timestamp"), Model.of(model.data.detection_time.toString()))
                        readonly(AnomalyLineGuardDetails::src_ip)
                        readonly(AnomalyLineGuardDetails::dest_ip)
                        readonly(AnomalyLineGuardDetails::protocol)
                        textArea(AnomalyLineGuardDetails::raw_line).apply {
                            lines = 3
                            readOnly = true
                        }
                    }
                }
                AnomalyModule.TimeGuard -> {
                    val details = anomalyWrapper.anomaly.attributes.timeguard_details
                    row(Model.of(details)) {
                        cssClassModifier("hgap-3 vgap-3")
                        legend("anomaly_details")
                        readonly(AnomalyTimeGuardDetails::time_range)
                        readonly(AnomalyTimeGuardDetails::rule_violation)
                    }
                    row(Model.of(details.statistics)) {
                        cssClassModifier("hgap-3 vgap-3")
                        val metrics = model.data.anomaly_specific_metrics
                        var suspiciousEvents = metrics.potential_attack.suspicious_event_ids.map { (k, v) -> k to v }
                        suspiciousEvents += (labelStrategy.label("total").data to SuspiciousEvent {
                            val statistics = model.data.log_entry_statistics
                            count = statistics.number_of_log_entries
                            val meanLabel = labelStrategy.label("mean_request_size").data
                            val medianLabel = labelStrategy.label("median_request_size").data
                            log_message =
                                "$meanLabel: ${statistics.mean_request_size} $medianLabel: ${statistics.median_request_size}"
                        })
                        decorateAndAddComponent(labelStrategy.label("statistics"), suspiciousEventTable(suspiciousEvents))
                    }
                }

                AnomalyModule.Unknown -> {

                }
            }
        }

    })

    private fun suspiciousEventTable(suspiciousEvents: List<Pair<String, SuspiciousEvent>>): KafffeComponent =
        BootstrapTable.create<Pair<String, SuspiciousEvent>>(suspiciousEvents) {
            applyDefaultStyle()
            modifiers.add(CssClassModifier("csaware-field"))
            col(labelStrategy.label("id"), { Label(it.first) })
            col(labelStrategy.label("count"), { Label(it.second.count.toString()) })
            col(labelStrategy.label("log_message"), { Label(it.second.log_message) })
        }


    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        addClass("anomaly-details")
        add(mainDetails.html)
    }
}