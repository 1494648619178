package dk.rheasoft.csaware.api.incident

import dk.rheasoft.csaware.json.adapter.MapAnySerializer
import dk.rheasoft.csaware.json.adapter.serialization.JsObjectAdapter
import dk.rheasoft.csaware.json.adapter.stix.Anomaly
import kotlinx.datetime.Instant
import kotlinx.serialization.EncodeDefault
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * CS-Aware visualition view on AI found Anomaly.
 * TODO change when we know the data from AI.
 */
@OptIn(ExperimentalSerializationApi::class)
@Serializable
data class AnomalyWrapper(
    val id: String,
    val created: Instant,
    var modified: Instant,

    @Serializable(with = MapAnySerializer::class)
    var anomalyJson: Map<String, Any?>,

    @SerialName("x_csaware_state")
    @EncodeDefault
    var csawareState: State = State.Todo
) {
    constructor(anomaly: Anomaly) : this(
        id = anomaly.id,
        created = anomaly.timestamp,
        modified = anomaly.timestamp,
        anomalyJson = (anomaly.adapter as JsObjectAdapter).jsonAsMap,
    )


    val anomaly: Anomaly get() = Anomaly(JsObjectAdapter.fromMap(anomalyJson))

    /**
     * Ids of "assets" node ids in system dependency graph this incident relates to.
     */
    val assets: List<String> get() = listOf(anomaly.attributes.critical_asset.asset_identifier)

    /**
     * The state of handling in CS-Aware. TODO adjust when we know the foarmat of data from DataLake/AI
     */
    enum class State {
        /**
         * The anomaly is not yet determined to be ignored or included in an Incident
         */
        Todo,
        /**
         * The anomaly is considered to be irrelvant and is ignored
         */
        Ignore,
        /**
         * The anomaly is considered to be part of an Incident and a Incident object referencing this Anomaly has been created.
         */
        Incident
    }

    companion object {
        fun fromMap(map : Map<String, Any?>) = AnomalyWrapper(Anomaly.fromMap(map))
    }
}
