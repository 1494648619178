package csaware.policy

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.*
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.utilities.DisplayLanguageDropdown
import dk.rheasoft.csaware.api.Language
import dk.rheasoft.csaware.api.PolicyData
import dk.rheasoft.csaware.api.PolicyDataExtractRequest
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.checkbox
import kafffe.bootstrap.form.textArea
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier

class PolicyDataImportDialog(
    title: Model<String>,
    policyData: PolicyData,
    val onAiResponse: (PolicyData) -> Unit
) : FormDialog<PolicyData>(title, Model.of(policyData)) {

    private val textModel: Model<String> = Model.of("")
    private val translateModel: Model<Boolean> = Model.of(true)
    private val languageModel: Model<Language> = Model.of(UserInformation.current.preferences.dataPresentationLanguage)

    private fun processText(button: BootstrapButton) {
        val request = PolicyDataExtractRequest(
            if (translateModel.data) languageModel.data.shortName else "",
            textModel.data
        )
        CsawareServices.policyBackend.policyDataFromText(request) {
            button.processingDone()
            onAiResponse(it.policyData)
            detach()
        }
    }

    init {
        cssClassModifier("vgap-3 hgap-3")
        labelStrategy = csawareMessageStrategy("policy_import_")
        size = ModalSize.extra_large

        // extract fileupload
        addChild(
            ofKafffeHtml {
                form {
                    val fileInput = input {
                        val thisElement = element
                        withElement {
                            id="upload_file"
                            type = "file"
                            accept = ".pdf,.docx"
                            style.display = "none"
                            addEventListener("change", {
                                val file = thisElement.files?.item(0)
                                if (file != null) {
                                    CsawareServices.policyBackend.extractText(file) { text: String ->
                                        textModel.data = text
                                    }
                                }
                            })
                        }
                    }
                    button {
                        addClass("btn btn-info")
                        i {
                            addClass("fa-solid file-import")
                        }
                        text(CsawareMessagesObject.get().policy_import_extract_text)
                        withElement {
                            onclick = {
                                fileInput.element.click()
                            }
                        }
                    }
                }
            }
        )

         textArea("txt", i18nText(CsawareMessages::policy_action_ai_import), textModel).apply {
             setModelChangedRerender()
             modifiers.add(StyleModifier {
                height = "60vh"
            })
            required = true
        }

        val lang = DisplayLanguageDropdown("lang", languageModel)
        decorateAndAdd(i18nText(CsawareMessages::languageSelect), lang)
        checkbox("translate", translateModel, i18nText(CsawareMessages::translation_automatic))
        button("import"){ btn ->
            processForm(
                onOk = { processText(btn) },
                onError =  {btn.processingDone() }
            )
        }.apply {
            color = BasicColor.primary
            iconClasses = "fa-solid fa-brain"
            useSpinner()
        }
        cancel().color = BasicColor.secondary
    }

}