package csaware.kpi_statistics

import kafffe.core.Model

enum class KpiDimensions() {
    d1, d2, d3, d4, d5;

    val questionnaireSubTitle: String get() = name.lowercase()

    //TODO translation in CsawareMessages
    val title: String get() = when (this) {
        d1 -> "Dynamic Cybersecurity Strategy"
        d2 -> "Collaborative Mindset"
        d3 -> "Organizational Learning"
        d4 -> "Regulatory Compliance"
        d5 -> "Standards & Technologies"
    }

    val titleModel: Model<String> get() = Model.of(title)
}