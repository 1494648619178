@file:Suppress("PropertyName")

package dk.rheasoft.csaware.json.adapter.stix

import dk.rheasoft.csaware.json.adapter.base.*
import dk.rheasoft.csaware.json.adapter.serialization.JsObjectAdapter
import kotlinx.datetime.Instant

@DslMarker
@Target(AnnotationTarget.CLASS, AnnotationTarget.TYPE)
annotation class AnomalyDslTag

@AnomalyDslTag
open class AnomalyDsl(jsonAdapter: JsonObjectAdapter) : ObjectAdapter(jsonAdapter) {
    fun toMap(): MutableMap<String, Any> = jsonAdapter().jsonAsMap
    fun jsonAdapter(): JsObjectAdapter = (adapter as JsObjectAdapter)
}

/**
 * Typed access to Anomaly "STIX" - todo make source_module specific types (attributes, details)
 */
open class Anomaly(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag Anomaly.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var id by JsonString()
    var type by JsonString()
    var timestamp: Instant by JsonTimestamp()
    var is_anomaly: Boolean by JsonBoolean()
    var attributes: AnomalyAttributes by JsonObject(::AnomalyAttributes)
    val category: String get() = anomaly_category
    var description: String by JsonString()
    var anomaly_category: String by JsonString()

    val module: AnomalyModule =
        AnomalyModule.entries.find { it.name.equals(type, ignoreCase = true) } ?: AnomalyModule.Unknown

    companion object {
        fun fromMap(map : Map<String, Any?>) = Anomaly(JsObjectAdapter.fromMap(map))
    }
}

enum class AnomalyModule {
    TimeGuard, LineGuard, Unknown;
}

open class AnomalyAttributes(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: (@AnomalyDslTag AnomalyAttributes).() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var anomaly_details: AnomalyDetails by JsonObject(::AnomalyDetails)
    var critical_asset: AnomalyCriticalAsset by JsonObject(::AnomalyCriticalAsset)

    var lineguard_details: AnomalyLineGuardDetails
        get() = anomaly_details.asLineGuard()
        set(value) { anomaly_details = value }

    var timeguard_details: AnomalyTimeGuardDetails
        get() = anomaly_details.asTimeGuard()
        set(value) { anomaly_details = value }

}

open class AnomalyDetails(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag AnomalyDetails.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }

    fun asLineGuard() : AnomalyLineGuardDetails = AnomalyLineGuardDetails(jsonAdapter())
    fun asTimeGuard() : AnomalyTimeGuardDetails = AnomalyTimeGuardDetails(jsonAdapter())
}


open class AnomalyCriticalAsset(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag AnomalyCriticalAsset.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var type: String by JsonString()
    var asset_identifier: String by JsonString()
}

open class AnomalyTimeGuardDetails(jsonAdapter: JsonObjectAdapter) : AnomalyDetails(jsonAdapter) {
    constructor(block: @AnomalyDslTag AnomalyTimeGuardDetails.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }

    // differs TimeGuard vs LineGuard how to make differnce and get the right one. Should we do inheritance top down?

    // only in timeguard
    var time_range: String by JsonString()
    var rule_violation: String by JsonString()
    var statistics: AnomalyStatistics by JsonObject(::AnomalyStatistics)
}

open class AnomalyLineGuardDetails(jsonAdapter: JsonObjectAdapter) : AnomalyDetails(jsonAdapter) {
    constructor(block: @AnomalyDslTag AnomalyLineGuardDetails.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var detection_time: Instant by JsonTimestamp()
    var src_ip: String by JsonString()
    var dest_ip: String by JsonString()
    var protocol: String by JsonString()
    var raw_line: String by JsonString()
}

class AnomalyStatistics(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag AnomalyStatistics.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var log_entry_statistics: LogEntryStatistics by JsonObject(::LogEntryStatistics)
    var anomaly_specific_metrics: AnomalySpecificMetrics by JsonObject(::AnomalySpecificMetrics)
}

class LogEntryStatistics(jsonAdapter: JsonObjectAdapter) : ObjectAdapter(jsonAdapter) {
    constructor(block: @AnomalyDslTag LogEntryStatistics.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var number_of_log_entries: Int by JsonInt()
    var distribution_of_event_ids: Map<String, Int> by JsonStringIntMap()
    var mean_request_size: Int by JsonInt()
    var median_request_size: Int by JsonInt()
}

class SuspiciousEvent(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag SuspiciousEvent.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var count: Int by JsonInt()
    var log_message: String by JsonString()
}

class PotentialAttack(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag PotentialAttack.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var suspicious_event_ids: Map<String, SuspiciousEvent> by JsonStringMap(::SuspiciousEvent)
    var duration: String by JsonString()
}

class AnomalySpecificMetrics(jsonAdapter: JsonObjectAdapter) : AnomalyDsl(jsonAdapter) {
    constructor(block: @AnomalyDslTag AnomalySpecificMetrics.() -> Unit) : this( JsObjectAdapter()) {
        block()
    }
    var potential_attack: PotentialAttack by JsonObject(::PotentialAttack)
}

