package csaware.test

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.utilities.UUID
import dk.rheasoft.csaware.api.incident.AnomalyWrapper
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import dk.rheasoft.csaware.json.adapter.stix.*
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kafffe.bootstrap.Alert
import kafffe.bootstrap.AlertsPanel
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapLevel
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.textArea
import kafffe.core.DivContainer
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.modifiers.StyleModifier
import kotlinx.datetime.Clock
import kotlinx.serialization.encodeToString
import org.w3c.dom.HTMLTextAreaElement
import kotlin.random.Random

class AnomalyTestPage : DivContainer() {

    val alerts = addChild(AlertsPanel())

    private val resourceIds: Model<List<String>> = Model.ofGet {
        CsawareServices.systemDependencyService.model.data.sortedBy(
            SystemDependencyResource::name
        ).map { it.id }
    }

    data class FormData(var anomalies: String = "")

    private lateinit var textAreaElement: HTMLTextAreaElement

    val form = addChild(BootstrapForm(Model.of(FormData())).apply {
        cssClassModifier("hgap-3 vgap-3")
        button(Model.of("Generate some random Anomalies")) { generateAnomalies() }.color = BasicColor.info

        textArea(FormData::anomalies).apply {
            modifiers.add(StyleModifier {
                height = "70vh"
                // width = "80vw"
            })
            required = true
            modifiers.add(HtmlElementModifier.create { textAreaElement = this as HTMLTextAreaElement })
        }

        submit("Visualisation", this@AnomalyTestPage::postVisualisation).color = BasicColor.primary
    })

    private fun postVisualisation() {
        alerts.clearAlerts()
        val anomaliesJsonString = form.model.data.anomalies
        println(anomaliesJsonString)
        backend().sendJsonTxt("/test/anomalies/consume", "POST", anomaliesJsonString) {
            alerts.infoAdd("Anomalies send")
        }
    }

    private fun errorHandler(code: Int, text: String) {
        alerts.addAlert(Alert("($code) $text", BootstrapLevel.danger))
    }

    private fun backend() = CSAwareBackend(this::errorHandler)

    private fun generateAnomalies() {
        alerts.clearAlerts()
        val count = Random.nextInt(1, 10)
        val anomalies: List<AnomalyWrapper> = (1..count).map { AnomalyWrapper(randomAnomaly()) }
        val json = JsonUtilSerialization.json.encodeToString(anomalies)
        textAreaElement.value = json
    }

    private fun randomAnomaly(): Anomaly =
        when (setOf(AnomalyModule.LineGuard, AnomalyModule.TimeGuard).random()) {
            AnomalyModule.TimeGuard -> randomTimeGuard()
            AnomalyModule.LineGuard -> randomLineGuard()
            AnomalyModule.Unknown -> randomTimeGuard()
        }

    private fun randomTimeGuard(): Anomaly = Anomaly {
        id = UUID.generateUuid("anomaly--")
        type = "timegaurd"
        anomaly_category = generateText(10, 30)
        description = generateText(50, 100)

        attributes = AnomalyAttributes {
            critical_asset = AnomalyCriticalAsset {
                type = "node"
                asset_identifier = resourceIds.data.random()
            }
            anomaly_details = AnomalyTimeGuardDetails {
                time_range = "2024-05-28T08:00:00Z to 2024-05-28T08:30:00Z"
                rule_violation = generateText(30, 50)
                statistics = AnomalyStatistics {
                    val eventIds = (1..4).map { (1000..9999).random() }
                    val eventCountMap: Map<Int, Int> = eventIds.map { it to (1..300).random() }.toMap()
                    val eventMessageMap: Map<Int, String> = eventIds.map { it to generateText(20, 30) }.toMap()
                    log_entry_statistics = LogEntryStatistics {
                        number_of_log_entries = eventCountMap.values.sum()
                        distribution_of_event_ids = eventCountMap.mapKeys { (k, _) -> "event_id_$k" }
                        mean_request_size = (100..1024).random()
                        median_request_size = (100..1024).random()
                    }
                    anomaly_specific_metrics = AnomalySpecificMetrics {
                        potential_attack = PotentialAttack {
                            suspicious_event_ids = eventCountMap.map { (k: Int, v: Int) ->
                                k.toString() to
                                        SuspiciousEvent {
                                            count = v
                                            log_message = generateText(30, 60)
                                        }
                            }.toMap()
                            duration = "${(10..45).random()} minutes"
                        }
                    }
                }
            }
        }
    }


    private fun randomLineGuard(): Anomaly = Anomaly {
        id = UUID.generateUuid("anomaly--")
        type = "lineguard"
        anomaly_category = generateText(10, 30)
        description = generateText(50, 100)
        attributes = AnomalyAttributes {
            critical_asset = AnomalyCriticalAsset {
                type = "node"
                asset_identifier = resourceIds.data.random()
            }
            anomaly_details = AnomalyLineGuardDetails {
                    detection_time = Clock.System.now()
                    src_ip = "127.0.0.1"
                    dest_ip = "127.0.0.1"
                    protocol = "UDP"
            }
        }
    }


    private fun generateText(minLength: Int, maxLength: Int): String {
        val sentences = arrayOf(
            "The quick brown fox jumps over the lazy dog.",
            "She sells seashells by the seashore.",
            "Peter Piper picked a peck of pickled peppers.",
            "How much wood would a woodchuck chuck if a woodchuck could chuck wood?",
            "I scream, you scream, we all scream for ice cream!"
        )

        var paragraph = ""
        while (paragraph.length < minLength) {
            paragraph += " ${sentences.random()}"
        }
        return paragraph.take(Random.nextInt(minLength, maxLength))
    }


}