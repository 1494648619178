package csaware.systemdepend.nodedetails

import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject
import csaware.messages.i18nText
import csaware.socialmedia.SocialMediaDetails
import csaware.systemdepend.SystemDependencyService
import csaware.threats.Popovers
import csaware.utilities.EllipsisLabel
import dk.rheasoft.csaware.api.QueryLimit
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import dk.rheasoft.csaware.api.socialmedia.SocialMediaAccount
import dk.rheasoft.csaware.api.socialmedia.SocialMediaEntry
import dk.rheasoft.csaware.api.socialmedia.SocialMediaType
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.messages.MessagesObject.formatDateTime
import kotlinx.datetime.toJSDate
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLCollection
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableRowElement
import kotlin.math.ceil

class SocialMedia(
    nodeSelectionModel: Model<SystemDependencyResource>,
    private val graphService: SystemDependencyService
) :
    KafffeComponentWithModel<SystemDependencyResource>(nodeSelectionModel) {


    init {
        modelChangedStandardBehavior = { pager.first() }
        modifiers.add(StyleModifier { display = if (table.model.data.isEmpty()) "none" else "block" })

    }

    private var totalCount: Int = 0
    val title: KafffeComponent = ofKafffeHtml {
        div {
            text(CsawareMessagesObject.get().socialmedia_reader)
            sup {
                addClass("ms-1 badge badge-pill bg-dark")
                element.style.fontSize = "0.7rem"
                text(totalCount.toString())
            }
        }
    }

    val table = addChild(BootstrapTable.create<SocialMediaEntry>(listOf()) {
        rowClickHandler = ::select

        addStyle(BootstrapTableStyles.striped)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiersHeader.add(CssClassModifier("bg-primary"))

        col(Model.of(""), { socialMediaEntry -> StateColumnWithAccount(socialMediaEntry) }).apply {
            rowClick = false
        }

        col(i18nText(CsawareMessages::socialmedia_createdAt), { Label(it.observation.createdAt.toJSDate().formatDateTime()) })
        colEx(i18nText(CsawareMessages::socialmedia_text), { data: SocialMediaEntry, cell: HTMLTableCellElement ->
            val text = data.getText()
            val title = data.getTitle()
            val fullText = when {
                title.isNotEmpty() && text.isNotEmpty() -> "$title\n\n$text"
                title.isNotEmpty() -> title
                else -> text
            }

            val model: Model<String> = Model.of(fullText)
            val label = EllipsisLabel("15vw", model, lines = 2)
            Popovers.text { model.data }.modify(cell)
            label

        })
        col(i18nText(CsawareMessages::socialmedia_keywords), { Label(it.matches.joinToString(", ")) })
    })


    inner class StateColumnWithAccount(private val entry: SocialMediaEntry) : KafffeComponent() {
        override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
            div {
                span {
                    addClass(
                        when (entry.observation.sourceType) {
                            SocialMediaType.Twitter -> "ms-2 sy sy_small sy_x-twitter"
                            SocialMediaType.Reddit -> "ms-2 sy sy_small sy_reddit"
                            else -> "ms-2 sy sy_small sy_rss"
                        }
                    )
                    withStyle {
                        whiteSpace = "nowrap"
                    }
                    withElement {
                        title = accountById[entry.observation.userId]?.name ?: entry.observation.userId
                    }
                }
            }
    }


    var pageSize: Int = 5

    val pager = Pager(1)
    private val paginator = addChild(BootstrapPagination(pager).apply {
        prevNextPage = true
        modifiers.add(CssClassModifier("float-end"))
    })
    private var filterInput: HTMLInputElement? = null

    private val selectionModel: Model<SocialMediaEntry?> = ModelNullable()
    private val whereSightedRefsModel: Model<Set<String>> = Model.ofGet {
        graphService
            .findAllHavingByKeywords(selectionModel.data?.matches ?: emptySet())
            .map { it.id }
            .toSet()
    }
    private val detailsWidget = addChild(SocialMediaDetails(
        selectionModel,
        whereSightedRefsModel,
        graphService,
        includeTitle = false
    ))

    init {
        pager.changeListeners.add { loadData() }
        loadAccounts()
    }

    private var accountById: Map<String, SocialMediaAccount> = mapOf()
    private fun loadAccounts() {
        CsawareServices.alerts.clearAlerts()

        CsawareServices.socialMediaBackend.getSocialMediaAccounts(
            0,
            1000
        ) { accountResult: QueryResult<SocialMediaAccount> ->
            accountById = accountResult.result.associateBy { it.id }
            table.rerender()
        }
    }


    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        val filter = filterInput?.value ?: ""
        val offset = pageSize * (pager.currentPage - 1)
        val keywords: Set<String> = model.data.keywords
        if (keywords.isNotEmpty()) {
            CsawareServices.socialMediaBackend.getSocialMediaEntries(
                keywords,
                UserInformation.current.preferences.dataPresentationLanguage.shortName,
                offset,
                pageSize,
                this::receiveData,
                filter
            )
        } else {
            receiveData(QueryResult<SocialMediaEntry>(0, listOf(), QueryLimit(offset, pageSize)))
        }
    }

    private fun receiveData(response: QueryResult<SocialMediaEntry>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
        rerender()
        totalCount = response.nofResult
        title.rerender()
    }

    fun select(entry: SocialMediaEntry, rowElement: HTMLTableRowElement) {
        Popovers.removeAll()
        selectionModel.data = entry
        rowElement.parentElement?.let { parent ->
            val childs: HTMLCollection = parent.children
            for (i in 0 until childs.length) {
                val child = childs.item(i)
                if (child is HTMLTableRowElement) {
                    child.removeClass("cs-aware-selected")
                }
            }
        }
        rowElement.addClass("cs-aware-selected")
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            add(table.html)
            add(paginator.html)
            div {
                withStyle { clear = "both" }
            }
            add(detailsWidget.html)
        }
    }

}