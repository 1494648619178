package csaware.comm

import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.incident.ThreatGroupWithTranslations
import dk.rheasoft.csaware.api.incident.ThreatObservation
import dk.rheasoft.csaware.api.incident.ThreatOverview
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResourcesThreatOverview
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import kotlinx.datetime.Instant

open class ThreatsBackend(exceptionHandler: (errorCode: Int, errorText: String) -> Unit) : CSAwareBackend(exceptionHandler) {
    fun getThreatConfiguredGroups(receiver: (List<ThreatGroupWithTranslations>) -> Unit) {
        getTxt("/threats/config/groups") {
            val x: List<ThreatGroupWithTranslations> = JsonUtilSerialization.json.decodeFromString(it)
            receiver(x)
        }
    }

    fun getThreatGroups(receiver: (Set<String>) -> Unit) {
        getTxt("/threats/groups") {
            val x: Array<String> = JSON.parse(it)
            receiver(x.toSet())
        }
    }

    fun getThreatsCurrentActive(
        atTime: Instant,
        offset: Int,
        pagesize: Int,
        receiver: (QueryResult<ThreatObservation>) -> Unit
    ) {
        getTxt("/threats/active?activeAt=$atTime&offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = ThreatObservation.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getThreatsOverview(atTime: Instant, receiver: (ThreatOverview) -> Unit) {
        getTxt("/threats/overview?activeAt=$atTime") {
            val x: ThreatOverview = ThreatOverview.fromJson(it)
            receiver(x)
        }
    }

    fun getThreatsCurrentActiveWithFilter(
        atTime: Instant,
        filter: ThreatFilter,
        offset: Int,
        pagesize: Int,
        receiver: (QueryResult<ThreatObservation>) -> Unit
    ) {
        getTxt(
            "/threats/active?activeAt=$atTime" +
                    "&group=${filter.groupSearchValue()}" +
                    "&assignee=${filter.assigneeSearchValue()}" +
                    "&where=${filter.whereSearchValue()}" +
                    "&offset=$offset&limit=$pagesize"
        ) {
            val x: QueryResult<ThreatObservation> = ThreatObservation.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getThreatsRecentClosed(offset: Int, pagesize: Int, receiver: (QueryResult<ThreatObservation>) -> Unit) {
        getTxt("/threats/closed?offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = ThreatObservation.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun getThreatsRecentClosedOfGroup(
        filter: ThreatFilter,
        offset: Int,
        pagesize: Int,
        receiver: (QueryResult<ThreatObservation>) -> Unit
    ) {
        getTxt(
            "/threats/closed" +
                    "?group=${filter.groupSearchValue()}" +
                    "&assignee=${filter.assigneeSearchValue()}" +
                    "&where=${filter.whereSearchValue()}" +
                    "&offset=$offset&limit=$pagesize"
        ) {
            val x: QueryResult<ThreatObservation> = ThreatObservation.fromQueryResultJson(it)
            receiver(x)
        }
    }

    fun updateThreatObservation(
        threatObservation: ThreatObservation,
        receiver: (ThreatObservation) -> Unit
    ) {
        sendJsonTxt("/threats/update", "POST", threatObservation.toJsonString()) {
            val x: ThreatObservation = ThreatObservation.fromJson(it)
            receiver(x)
        }
    }

    fun createThreatObservation(
        threatObservation: ThreatObservation,
        receiver: (ThreatObservation) -> Unit
    ) {
        sendJsonTxt("/threats/create", "POST", threatObservation.toJsonString()) {
            val x: ThreatObservation = ThreatObservation.fromJson(it)
            receiver(x)
        }
    }

    fun threatWithDetails(threatObservationId: String, receiver: (ThreatObservation) -> Unit) {
        getTxt("/threats/$threatObservationId") {
            val x: ThreatObservation = ThreatObservation.fromJson(it)
            receiver(x)
        }
    }

    fun getSystemResourceOverview(atTime: Instant, receiver: (SystemDependencyResourcesThreatOverview) -> Unit) {
        getTxt("/threats/systemresource/overview?activeAt=$atTime&limit=1000") {
            val x: SystemDependencyResourcesThreatOverview = SystemDependencyResourcesThreatOverview.fromJson(it)
            receiver(x)
        }
    }

    fun getSystemResourceThreatsCurrentActive(
        atTime: Instant,
        systemResourceId: String,
        offset: Int,
        pagesize: Int,
        receiver: (QueryResult<ThreatObservation>) -> Unit
    ) {
        getTxt("/threats/systemresource/active?activeAt=$atTime&id=$systemResourceId&offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = ThreatObservation.fromQueryResultJson(it)
            receiver(x)
        }
    }


}

