package csaware.policy

import csaware.messages.CsawareMessagesObject
import csaware.utilities.markdown.MarkdownRender
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import csaware.main.navigateTo
import csaware.systemdepend.SystemDependencyService
import dk.rheasoft.csaware.api.Policy

/**
 * Renders policy data as a "documnent" readonly HTML
 */
class PolicyDataView(
    model: Model<Policy>,
    private val graphService: SystemDependencyService
) : KafffeComponentWithModel<Policy>(model) {
    private val linkClass: String = "link-primary"
    private val namesFromIds = graphService.namesFromIds(model.data.policyData.systemNodeReferences)
    init {
        setModelChangedRerender()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        with(model.data) {
            h1 { text(policyData.title) }

            h1 { text(CsawareMessagesObject.get().policy_purpose) }
            add(MarkdownRender(policyData.purpose).html)

            h1 { text(CsawareMessagesObject.get().policy_elements) }
            add(MarkdownRender(policyData.elements).html.apply { style.maxHeight= "none" } )

            h1 { text(CsawareMessagesObject.get().policy_need) }
            add(MarkdownRender(policyData.need).html.apply { style.maxHeight= "none" })

            h1 { text(CsawareMessagesObject.get().policy_roles_responsibilities) }
            add(MarkdownRender(policyData.rolesResponsibilities).html.apply { style.maxHeight= "none" })

            h1 { text(CsawareMessagesObject.get().policy_references) }
            add(MarkdownRender(policyData.references).html.apply { style.maxHeight= "none" })

            h1 { text(CsawareMessagesObject.get().policy_systemNodeReferences) }
            add( div{
                namesFromIds.forEachIndexed{ index: Int, name: String ->
                    a {
                        text(name)
                        withElement {
                            className = linkClass
                            style.whiteSpace = "nowrap"
                            href="#"
                            onclick = {
                                onclick(name)
                            }
                        }
                    }
                    if (index < namesFromIds.size - 1) {
                        text(", ")
                    }
                }
            })

            h1 { text(CsawareMessagesObject.get().policy_tags) }
            text(policyData.tags.joinToString(", "))

            h1 { text(CsawareMessagesObject.get().accessLevel) }
            add(MarkdownRender(CsawareMessagesObject.get().accessLevelName(accessLevel)).html)
        }
    }
    fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}