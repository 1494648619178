package csaware.ecosystem

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.CsawareServices.systemDependencyService
import csaware.main.CsawareServices.threatsBackend
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.DependencyDropdown
import csaware.systemdepend.SystemSearch
import csaware.systemdepend.UiFunctions
import csaware.systemdepend.graph.SystemGraph
import csaware.systemdepend.nodedetails.NodeDetails
import csaware.utilities.ActionBar
import csaware.utilities.StringSelector
import csaware.utilities.componentTitle
import dk.rheasoft.csaware.api.*
import dk.rheasoft.csaware.api.access.MainFeature
import dk.rheasoft.csaware.api.access.Permission
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResourcesThreatOverview
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.bootstrapCol
import kafffe.bootstrap.bootstrapRow
import kafffe.core.*
import kotlinx.datetime.Clock

// TODO eco system selector with automatic selection if only one (hardcode to a start).
// adjust views to work with correct graph/service
// systemDependcyService change to support eco-system.
class EcoSystemGraph(val ecoSystemId: String, selectResource: SystemDependencyResource? = null) : KafffeComponent() {
    private val graphService = CsawareServices.ecoSystemGraphService(ecoSystemId)

    private val resources: Model<List<SystemDependencyResource>> = graphService.model
    private val threatSummary: Model<SystemDependencyResourcesThreatOverview> =
        Model.of(SystemDependencyResourcesThreatOverview.empty())

    private val graphLabel = addChild(Label(i18nText(CsawareMessages::eco_system)))
    val graph = addChild(
        SystemGraph(
            resources,
            graphService.config,
            threatSummary,
            graphService.keywordCount,
            graphService,
            selectResource
        )
    )

    private val titleLabel = addChild(Label(graph.selectionModel.property(SystemDependencyResource::name)))
    val details = addChild(NodeDetails(graph.selectionModel, graph.hightlightModel, graphService))

    private val searchBox = addChild(SystemSearch(graph.selectionModel, resources, graphService))
    private val infoFlowBox = addChild(
        StringSelector(
            graph.hightlightModel,
            Model.of(graphService.config.data.getInfoflowValues().sorted()),
            SystemGraph.SELECTION_HIGHLIGHT
        )
    )
    private val dependencySelector = addChild(
        DependencyDropdown(graphService.config, graph.dependencyFieldModel)
    )

    init {
        loadThreats()
        if (selectResource != null) {
            graph.panToSelection()
        }
    }

    private val onResourcesUpdate = ModelChangeListener {
        loadThreats()
    }

    private val onServerUpdate: (UpdateEvent) -> Unit = { event: UpdateEvent ->
        CsawareServices.alerts.clearAlerts()
        when (event.type) {
            UpdateEvent.EntityType.ThreatObservation -> loadThreats()
            UpdateEvent.EntityType.SystemDependencies -> {
                graphService.refresh()
            }

            UpdateEvent.EntityType.SystemDependencyNode -> {
                graphService.refresh()
            }

            UpdateEvent.EntityType.SystemDependencyEdge -> {
                graphService.refresh()
            }

            UpdateEvent.EntityType.SystemDependencyConfig -> {
                graphService.refreshConfig()
            }

            else -> {}
        }
    }


    private fun loadThreats() {
        threatsBackend.getSystemResourceOverview(Clock.System.now()) {
            threatSummary.data = it
        }
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        resources.listeners.add(onResourcesUpdate)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        resources.listeners.remove(onResourcesUpdate)
        super.detach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return bootstrapRow {
            bootstrapCol(ColWidth(ResponsiveSize.sm, 8)) {
                div {
                    componentTitle {
                        add(graphLabel.html)
                        add(searchBox.html)
                        add(infoFlowBox.html)
                        add(dependencySelector.html)
                        if (UserInformation.hasAccess(MainFeature.SystemDependencies, Permission.Write)) {
                            add(actionBar.html)
                        }
                    }
                    add(graph.html)
                }
            }
            bootstrapCol(ColWidth(ResponsiveSize.sm, 4)) {
                componentTitle {
                    add(titleLabel.html)
                }
                add(details.html)
            }
        }
    }

    private val actionBar = addChild(ActionBar {
        for (func in UiFunctions.resourceFunctions) {
            item(Model.of(func.label), func.iconCls) {
                accessRequirement = func.feature to func.permission
                action = { func.doIt(graph.selectionModel.data, graph.selectionModel, graphService) }
            }
        }
        dropdownItem(Model.of(""), "fas fa-cog") {
            for (func in UiFunctions.globalFunctions) {
                item(Model.of(func.label), func.iconCls) {
                    accessRequirement = func.feature to func.permission
                    action = { func.doIt(graph.selectionModel.data, graph.selectionModel, graphService) }
                }
            }
        }
    })

}