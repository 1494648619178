package csaware.policy


import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.main.navigateTo
import csaware.main.navigateToComponent
import csaware.messages.CsawareMessages
import csaware.messages.CsawareMessagesObject.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.systemdepend.SystemDependencyService
import csaware.utilities.AccessLevelDropdown
import csaware.utilities.UUID
import csaware.utilities.markdown.MarkDownInput
import csaware.utilities.tagEditor
import dk.rheasoft.csaware.api.AccessLevel
import dk.rheasoft.csaware.api.Policy
import dk.rheasoft.csaware.api.PolicyType
import dk.rheasoft.csaware.api.PolicyState
import dk.rheasoft.csaware.api.PolicyData
import dk.rheasoft.csaware.api.systemdependencies.SystemDependencyResource
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.*
import kafffe.core.Model
import kafffe.core.func
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.core.property
import kotlinx.datetime.Clock

class PolicyEditor(
    val isEdit: Boolean,
    policy: Policy,
    val returnTarget: String,
    private val graphService: SystemDependencyService
) : BootstrapForm<Policy>(Model.of(policy)) {
    companion object {
        fun forCreate(returnTarget: String, graphService: SystemDependencyService): PolicyEditor {
            val policy = Policy(
                id = UUID.generateUuid("policy--"),
                policyData = PolicyData(),
                createdBy = UserInformation.current.email,
                createdAt = Clock.System.now(),
                updatedBy = UserInformation.current.email,
                updatedAt = Clock.System.now()
            )
            return PolicyEditor(isEdit = false, policy, returnTarget, graphService)
        }

        fun forEdit(policy: Policy, returnTarget: String, graphService: SystemDependencyService): PolicyEditor {
            if(policy.policytype == PolicyType.TEMPLATE){
                val newPolicy = Policy(
                    id = UUID.generateUuid("policy--"),
                    policyData = policy.policyData,
                    createdBy = UserInformation.current.email,
                    createdAt = Clock.System.now(),
                    updatedBy = UserInformation.current.email,
                    updatedAt = Clock.System.now()
                )
                return PolicyEditor(isEdit = true, newPolicy, returnTarget, graphService)
            } else
            return PolicyEditor(isEdit = true, policy, returnTarget, graphService)
        }

        fun forClone(policy: Policy, returnTarget: String, graphService: SystemDependencyService): PolicyEditor {
            //TODO add policy-relation to original
            val clone = policy.copy(
                id = UUID.generateUuid("policy--"),
                policyData = policy.policyData.copy(title = "CLONE of: ${policy.policyData.title}"),
                state = PolicyState.DRAFT,
                createdBy = UserInformation.current.email,
                createdAt = Clock.System.now(),
                updatedBy = UserInformation.current.email,
                updatedAt = Clock.System.now()
            )
            return PolicyEditor(isEdit = false, clone, returnTarget, graphService)
        }
    }

    private val isEditScreen = isEdit
    private val systemResourceIds =
        graphService.model.data.sortedBy(SystemDependencyResource::name).map { it.id }

    // Map functional submodel list to mutable set of ids
    private val connectedToNodeModel: Model<List<String>> = model.func(
        { p -> p.data.policyData.systemNodeReferences.toList() },
        { p, v ->
            p.data.policyData.systemNodeReferences.clear()
            p.data.policyData.systemNodeReferences.addAll(v)
        }
    )

    private val whereSightedRefs =
        object : MultipleEditSelect<String>("connectedToNodeRefs", connectedToNodeModel, Model.of(systemResourceIds)) {
            override fun display(choice: String): String =
                graphService.byId(choice)?.name ?: ""
        }

    private val commentsEditor = PolicyCommentsEditor(isEdit, model)

    init {
        labelStrategy = csawareMessageStrategy("policy_")

        // We need some gap because we do not apply whitespace between elements.
        cssClassModifier("hgap-3 vgap-3")
        addUpdateButtons()
        row {
            col(ColWidth(ResponsiveSize.md, 8), subModel = Model.of(policy.policyData)) {
                button(i18nText(CsawareMessages::policy_action_ai_import)) {
                    aiImport()
                }
                styleModifier { maxWidth = "128ch" }
                addPolicyData()
                val policyModel = Model.of(policy)
                //AccessLEvel.Frontendlocal is not possible to chose on policy. To complicated to handle if accesslevel is changed to something else when policy has been approved.
                val values: List<AccessLevel> =  AccessLevel.entries.filter { it.selectable }.filter{ it != AccessLevel.FrontendLocal }
                val accessLevelDropdown = AccessLevelDropdown("accessLevel", policyModel.property(Policy::accessLevel),values)
                group {
                    // Make larger labels
                    inputDecorator = { labelModel: Model<String>, inputComponent: FormInput ->
                        FormInputGroupDecorator(labelModel, inputComponent).apply {
                            modifiersLabel.add(CssClassModifier("h1"))
                        }
                    }
                    decorateAndAddComponent(
                        i18nText(CsawareMessages::accessLevel),
                        accessLevelDropdown
                    )
                }
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                styleModifier { maxWidth = "80ch" }
                addChild(commentsEditor)
            }
        }
        addUpdateButtons()
    }

    private fun aiImport() {
        val policyData = model.data.policyData.copy()
        PolicyDataImportDialog(i18nText(CsawareMessages::policy_action_ai_import), policyData) { newData ->
            navigateToComponent(
                PolicyEditor(
                    isEdit,
                    this@PolicyEditor.model.data.copy(policyData = newData),
                    returnTarget,
                    graphService
                )
            )
        }.attach()
    }

    private fun addUpdateButtons() {
        submit("save", ::save).color = BasicColor.success
        if (isEditScreen) {
            submit("delete", onOk = {
                CsawareServices.alerts.clearAlerts()
                CsawareServices.policyBackend.deletePolicy(model.data) {
                    navigateTo(returnTarget)
                }
                detach()
            }).color = BasicColor.danger
        }
        cancel().color = BasicColor.secondary
    }

    override fun onCancel() {
        navigateTo(returnTarget)
    }

    private fun FormLayout<PolicyData>.addPolicyData() {
        group {
            // Make larger labels
            inputDecorator = { labelModel: Model<String>, inputComponent: FormInput ->
                FormInputGroupDecorator(labelModel, inputComponent).apply {
                    modifiersLabel.add(CssClassModifier("h1"))
                }
            }
            cssClassModifier("hgap-3 vgap-3 mb-3 mt-4")

            input(PolicyData::title)
            val purposeInput = MarkDownInput(model.property(PolicyData::purpose)).apply {
                cssClassModifier("form-control")
            }
            decorateAndAddComponent(labelStrategy.label("purpose"), purposeInput)

            val elementsInput = MarkDownInput(model.property(PolicyData::elements)).apply {
                cssClassModifier("form-control")
            }
            decorateAndAddComponent(labelStrategy.label("elements"), elementsInput)

            val needInput = MarkDownInput(model.property(PolicyData::need)).apply {
                cssClassModifier("form-control")
            }
            decorateAndAddComponent(labelStrategy.label("need"), needInput)

            val rolesResponsibilitiesInput = MarkDownInput(model.property(PolicyData::rolesResponsibilities)).apply {
                cssClassModifier("form-control")
            }
            decorateAndAddComponent(labelStrategy.label("rolesResponsibilities"), rolesResponsibilitiesInput)

            val referencesInput = MarkDownInput(model.property(PolicyData::references)).apply {
                cssClassModifier("form-control")
            }
            decorateAndAddComponent(labelStrategy.label("references"), referencesInput)

            decorateAndAddComponent(i18nText(CsawareMessages::policy_systemNodeReferences), whereSightedRefs)

            tagEditor(PolicyData::tags, tagSuggestions())
        }
    }

    private fun tagSuggestions(): Model<List<String>> =
        //TODO get tag suggestions from?
        Model.of(
            listOf(
                "Protect", "Prevent",
                "Confidentiality",
                "Integrity",
                "Availability",
                "Authentication",
                "Authorization",
                "Accountability",
                "Non-repudiation",
                "Resilience",
                "Risk Management",
                "Compliance",
                "Incident Response",
                "Vulnerability Management",
                "Security Awareness",
                "Data Protection",
                "Encryption",
                "Monitoring and Logging",
                "Network Segmentation",
                "Patch Management",
                "Access Control",
                "Firewall Configuration",
                "Intrusion Detection Systems (IDS)",
                "Intrusion Prevention Systems (IPS)",
                "Virtual Private Networks (VPNs)",
                "Penetration Testing",
                "Security Policy Enforcement",
                "Disaster Recovery Planning",
                "Business Continuity",
                "Third-Party Risk Management",
                "Cloud Security",
                "Mobile Device Security"
            )
        )


    private fun save() {
        CsawareServices.alerts.clearAlerts()
        val policy = model.data
        if(policy.state == PolicyState.APPROVED){
            //should we validate if anything actualy has changed or just react to the fact that the user presses save on an approved policy
            policy.state= PolicyState.DRAFT_WITH_APPROVED
        }
        CsawareServices.policyBackend.storePolicy(policy) {
            if (!isEdit) {
                commentsEditor.saveComments { navigateTo(returnTarget) }
            } else {
                navigateTo(returnTarget)
            }
        }
        detach()
    }

}