package csaware.kpi_statistics

import csaware.main.CsawareServices
import dk.rheasoft.csaware.api.statistics.StatisticCounter
import dk.rheasoft.csaware.api.statistics.StatisticEntry
import dk.rheasoft.csaware.utils.JsonUtilSerialization
import dk.rheasoft.csaware.utils.weekNumber
import externals.echarts.BarSeriesOption
import externals.echarts.EChartsOption
import externals.echarts.TooltipOption
import externals.echarts.ZRender
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kafffe.utils.DomObserverUtils
import kotlinx.datetime.Instant
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.char
import org.w3c.dom.HTMLDivElement

class CounterColumnChart(
    val titleModel: Model<String>,
    val counter: StatisticCounter,
    val maxNofCounts: Int = 10
) : KafffeComponent() {

    var entries: List<StatisticEntry> by rerenderOnChange(emptyList())
    var averageEntries: List<StatisticEntry> by rerenderOnChange(emptyList())
    var height: String by rerenderOnChange("20rem")
    var width: String by rerenderOnChange("40rem")

    init {
        load()
    }

    private fun load() {
        val parameters = mapOf(
            "title" to counter.title,
            "subTitle" to counter.subTitle,
            "countType" to counter.countType.name,
            "offset" to "0",
            "limit" to "$maxNofCounts"
        )
        CsawareServices.backend.getTxt("/statistics/counter/newest", parameters) {
            entries = JsonUtilSerialization.json.decodeFromString(it)
        }
        CsawareServices.backend.getTxt("/statistics/counter/average/newest", parameters) {
            averageEntries = JsonUtilSerialization.json.decodeFromString(it)
        }
    }

    fun chart(chartElement: HTMLDivElement) {
        var myChart: ZRender = externals.echarts.init(chartElement)
        var option: EChartsOption = js(
            """{
                   xAxis : {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                   },
                   yAxis: { type: 'value' },
                   legend: { data: ['this', 'Average'] },
                   series: [
                        {
                            data: [120, 200, 150, 80, 70, 110, 130],
                            type: 'bar'
                        }
                   ]
            }
            """
        ).unsafeCast<EChartsOption>()
        option.tooltip = js("{}")
        with (option.tooltip as TooltipOption) {
            trigger = "axis"
            axisPointer = js("{}")
            axisPointer!!.type = "shadow"
        }

        val barSeriesOptions = (option.series as Array<BarSeriesOption>)
        barSeriesOptions[0].name = titleModel.data
        barSeriesOptions[0].data = entries.reversed().map { it.value }.toTypedArray()
        // set bar color
        barSeriesOptions[0].itemStyle = js("{}")
        barSeriesOptions[0].itemStyle!!.color = "#4AB5E3"

        val periodStarts: List<Instant> = entries.reversed().map { it.periodStart }
        option.legend.data = listOf(titleModel.data).toTypedArray()
        option.xAxis.data = when (counter.countType) {
            StatisticCounter.CountType.DAILY -> periodStarts.map { day(it) }.toTypedArray()
            StatisticCounter.CountType.WEEKLY -> periodStarts.map { it.weekNumber() }.toTypedArray()
            StatisticCounter.CountType.MONTHLY -> periodStarts.map { month(it) }.toTypedArray()
            else -> periodStarts.map { it.toString() }.toTypedArray()
        }

        if (averageEntries != entries && averageEntries.size == entries.size) {
            val average = js("{}") as BarSeriesOption
            option.legend.data = listOf(titleModel.data, "Average").toTypedArray()
            average.name = "Average"
            average.type = "bar"
            //  average.barGap = "-25%"
            average.data = averageEntries.reversed().map { it.value }.toTypedArray()
            barSeriesOptions[1] = average
        }
        myChart.setOption(option)
    }

    private fun day(periodStart: Instant): String =
        periodStart.format(DateTimeComponents.Format { year(); char('-'); monthNumber(); char('-'); dayOfMonth() })

    private fun month(periodStart: Instant): String =
        periodStart.format(DateTimeComponents.Format { year(); char('-'); monthNumber() })


    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("csaware_counter_column_chart")
            withElement {
                style.height = height
                style.width = width
            }
            DomObserverUtils.whenVisibleInViewport(this.element) {
                chart(it)
            }
        }
}