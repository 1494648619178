package csaware.comm

import dk.rheasoft.csaware.api.MailSimple
import dk.rheasoft.csaware.api.TranslationText
import dk.rheasoft.csaware.api.UpdateEvent
import kotlinx.browser.window
import org.w3c.dom.WebSocket
import org.w3c.dom.events.Event
import org.w3c.files.File
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import encodeURIComponent


open class CSAwareBackend(val exceptionHandler: (errorCode: Int, errorText: String) -> Unit) {
    class WebSocketReconnect(val endpoint: String, val messageReceiver: (String) -> Unit) {
        private val reconnectInterval = 10 * 1000

        init {
            open()
        }

        fun open() {
            try {
                println("WebSocket open")
                WebSocket(endpoint).apply {
                    onmessage = {
                        messageReceiver(it.data as String)
                    }

                    onerror = {
                        // IGNORE -  REOPEN ONLY ONCLOSE   reopenHandler()
                    }
                    onclose = {
                        reopenHandler()
                    }

                }
            } catch (e: Throwable) {
                window.setTimeout({ open() }, reconnectInterval)
            }
        }

        private fun reopenHandler() {
            println("WebSocket connection error/closed - reconnect attempt in a few seconds")
            window.setTimeout({ open() }, reconnectInterval)
        }

    }

    companion object {
//        val loc = window.location
//        var serverEndpoint = """${loc.protocol}//${loc.host}""" // kafffenv.csawareBackend
//        var serverWsEndpoint = """${if (loc.protocol == "https:") "wss" else "ws"}://${loc.host}/updates"""

        val loc = window.location
        val context = if (loc.pathname.startsWith("/cs-aware", ignoreCase = true)) "/cs-aware" else ""
        var serverEndpoint = """${loc.protocol}//${loc.host}$context""" // kafffenv.csawareBackend
        private var serverWsEndpoint = """${if (loc.protocol == "https:") "wss" else "ws"}://${loc.host}$context/updates"""

        var updateListeners = mutableListOf<(event: UpdateEvent) -> Unit>()

        init {
            WebSocketReconnect(serverWsEndpoint) {
                val event = UpdateEvent.fromJson(it)
                for (l in updateListeners) {
                    l(event)
                }
            }
        }
    }

    private var requestHeaders = mapOf(
        "Content-Type" to "application/json"
    )

    private var requestHeadersFile = mapOf(
        "Content-Type" to "multipart/form-data"
    )


    fun translate(data: TranslationText, receiver: (translation: TranslationText) -> Unit) {
        sendJsonTxt("/translate", "POST", data.toJsonString()) {
            val translation = TranslationText.fromJson(it)
            receiver(translation)
        }
    }


    fun sendMail(mail: MailSimple, receiver: (String) -> Unit) {
        sendJsonTxt("/mail/send", "PUT", mail.toJson()) {
            receiver(it)
        }
    }

    fun getTxt(path: String, parameters: Map<String,String>,bodyReceiver: (body: String) -> Unit) =
        getTxt(path + "?" + encodeQueryParameters(parameters), bodyReceiver)

    fun getTxt(path: String, bodyReceiver: (body: String) -> Unit) {
        console.log("GET path: $path")
        val req = XMLHttpRequest()
        with(req) {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open("GET", "${serverEndpoint}${path}")
            for ((key, value) in requestHeaders) {
                setRequestHeader(key, value)
            }

        }
        req.send()
    }

    fun sendJsonTxt(path: String, method: String, json: String, bodyReceiver: (body: String) -> Unit) {
        val req = XMLHttpRequest()
        with(req) {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open(method, "${serverEndpoint}${path}")
            for ((key, value) in requestHeaders) {
                setRequestHeader(key, value)
            }

        }
        req.send(json)
    }

    fun postJson(path: String, json: String, bodyReceiver: (body: String) -> Unit) {
        sendJsonTxt(path, "POST", json, bodyReceiver)
    }

    fun putJson(path: String, json: String, bodyReceiver: (body: String) -> Unit) {
        sendJsonTxt(path, "PUT", json, bodyReceiver)
    }

    fun sendFile(path: String, method: String, file: File, bodyReceiver: (body: String) -> Unit) {
        val req = XMLHttpRequest().apply {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open(method, "${serverEndpoint}${path}", true)
//            for ((key, value) in requestHeadersFile) {
//                setRequestHeader(key, value)
//            }

            val formData = FormData()
            formData.append("file", file, file.name)
            send(formData)
        }
    }

    open fun handleError(request: XMLHttpRequest, event: Event) =
        if (request.status.toInt() == 0 && request.statusText.isEmpty()) {
            exceptionHandler(0, "Unable to communicate with backend server")
        } else {
            if (request.status.toInt() in setOf(401, 403)) {
                window.location.replace("${serverEndpoint}/login")
            } else {
                if(request.responseText.isNullOrEmpty()){
                    exceptionHandler(request.status.toInt(), request.statusText)
                } else {
                    exceptionHandler(request.status.toInt(),request.statusText+": "+request.responseText)
                }
            }
        }


    /**
     * Encodes query parameters for a request. The result is separated by & and values are encoded, but no ? is include
     */
    fun encodeQueryParameters(parameters: Map<String, String>): String =
        parameters.entries.joinToString("&") { "${it.key}=${encodeURIComponent(it.value)}" }


}

