package dk.rheasoft.csaware.api.incident

// TODO rewite change state values and new BCDR + Contextulized states
enum class ThreatState(val active: Boolean = false) {
    Active(true),
    Resolved,
    Ignored,
    HealingAwaitDecision(true),
    HealingAccepted(true),
    HealingDeclined(true),
    Healed(true),
    HealingFailed(true),
    HealingInProgress(true),
    Unknown;

    companion object {
        fun parse(value: String): ThreatState = try { valueOf(value) } catch (any: Throwable) {
            Unknown
        }
    }
}